import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StatusChip from "../../components/StatusChip";

const useStyles = makeStyles(({ typography, palette, spacing }: Theme) =>
  createStyles({
    root: {},
    avatar: {
      width: spacing(5),
      height: spacing(5),
      fontSize: "1rem"
    },
    chip: {
      width: "min-content",
      padding: "0.25rem"
    },
    textsBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& p": {
        marginBottom: "0.125rem",
        "& > span:first-of-type": {
          fontWeight: typography.fontWeightBold,
          "&::after": {
            display: "inline",
            content: '" "'
          }
        }
      }
    }
  })
);

export default function UserStatus({ name, role, status }: any) {
  const classes = useStyles();
  const userType = role === "doctor" ? "MD" : "SH";

  return (
    <Box className={classes.root}>
      <Box className={classes.textsBox}>
        <Typography>
          <span>{userType}</span>
          <span>{name}</span>
        </Typography>
        <StatusChip
          className={classes.chip}
          type={userType}
          status={status}
          hideAvatar
        />
      </Box>
    </Box>
  );
}
