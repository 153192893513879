import React, { useContext, useReducer } from "react";
import { PatientData } from "./patient";

export enum ActionTypes {
  UpdatePatient,
  UpdateNotification
}
type Action = {
  type: ActionTypes;
  patient?: PatientData;
  notification?: boolean;
};
export type Dispatch = (action: Action) => void;
export type State = {
  patient: PatientData | null;
};
type ProviderProps = { children: React.ReactNode };

const PatientStateContext = React.createContext<State | undefined>(undefined);
const PatientDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function patientReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.UpdatePatient:
      return action.patient ? { ...state, patient: action.patient } : state;
    case ActionTypes.UpdateNotification:
      return action.notification !== undefined
        ? {
            ...state,
            patient: {
              ...(state.patient || {}),
              notification: action.notification
            } as PatientData
          }
        : state;
    default:
      return state;
  }
}

export function usePatientState() {
  const context = useContext(PatientStateContext);
  if (!context) {
    throw new Error("usePatientState must be used inside PatientContext");
  }
  return context;
}

export function usePatientDispatch() {
  const context = useContext(PatientDispatchContext);
  if (!context) {
    throw new Error("usePatientDispatch must be used inside PatientContext");
  }
  return context;
}

export function PatientProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(patientReducer, {
    patient: null
  });

  return (
    <PatientStateContext.Provider value={state}>
      <PatientDispatchContext.Provider value={dispatch}>
        {children}
      </PatientDispatchContext.Provider>
    </PatientStateContext.Provider>
  );
}
