import React, {
  Reducer,
  useEffect,
  useReducer,
  useState,
  useCallback
} from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  InputLabel,
  Input,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Card
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Ticket, TicketComment, TicketStatus } from "../../api/ticket";
import useCollectionPagination from "../../utils/useCollectionPaginator";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import TicketListItem from "./TicketListItem";
import {
  usePatientState,
  usePatientDispatch,
  ActionTypes as PatientActionTypes
} from "../../api/PatientProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
      overflow: "auto",
      marginTop: "2rem"
    },
    overflowContainer: {
      position: "relative",
      padding: theme.spacing(1)
    },
    title: {
      marginTop: "20px",
      marginBottom: "10px",
      textAlign: "center"
    },
    searchBarHelperText: {
      textAlign: "center"
    },
    progressContainer: {
      position: "relative"
    },
    listProgress: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0
    },
    typeSelect: {
      minWidth: 200
    },
    selectTickets: {
      marginLeft: 0,
      marginTop: "1rem",
      marginBottom: "0.5rem"
    },
    alert: {
      width: "300px",
      marginTop: "1rem",
      padding: 0,
      background: "transparent"
    },
    card: {
      padding: "1rem"
    }
  })
);

type TicketListReducerAction =
  | { type: "reset"; payload: { items: Ticket[] } }
  | {
      type: "changeTicketStatusById";
      payload: { id: string; status: TicketStatus };
    }
  | {
      type: "addCommentToTicket";
      payload: { id: string; comment: TicketComment };
    };

function TicketListReducer(
  prevState: Ticket[],
  action: TicketListReducerAction
): Ticket[] {
  // https://github.com/Microsoft/TypeScript/issues/12220
  switch (action.type) {
    case "reset": {
      return [...action.payload.items];
    }
    case "changeTicketStatusById": {
      const newState = [...prevState];

      const foundIndex = prevState.findIndex(i => i.id === action.payload.id);

      if (foundIndex > -1) {
        newState[foundIndex] = {
          ...newState[foundIndex],
          status: action.payload.status
        };
      }

      return newState;
    }
    case "addCommentToTicket": {
      const newState = [...prevState];

      const foundIndex = prevState.findIndex(i => i.id === action.payload.id);

      if (foundIndex > -1) {
        const oldTicketComments = newState[foundIndex].comments;
        const newTicketComments = oldTicketComments
          ? [...oldTicketComments]
          : [];
        newTicketComments.splice(0, 0, action.payload.comment);

        newState[foundIndex] = {
          ...newState[foundIndex],
          comments: newTicketComments
        };
      }

      return newState;
    }
    default:
      throw new Error("Unsupported action");
  }
}

function filterTicketsByStatus(
  tickets: Ticket[],
  status: TicketStatus | "any"
): Ticket[] {
  if (status === "any") {
    return tickets;
  }

  return tickets.filter(ticket => ticket.status === status);
}

export default function Tickets(props: any) {
  const classes = useStyles();
  const [filterStatus, setFilterStatus] = useState<TicketStatus | "any">("any");
  const patientTicketsState = usePatientState().patient?.tickets;
  const paginationData = useCollectionPagination<Ticket>();
  const [ticketItemsState, dispatch] = useReducer<
    Reducer<Ticket[], TicketListReducerAction>
  >(TicketListReducer, patientTicketsState || []);
  const patientDispatch = usePatientDispatch();

  const scrollContainerRef: any = useBottomScrollListener(
    () => {
      if (ticketItemsState.length > paginationData.page.length) {
        paginationData.setRowsCount(rowsCount => rowsCount + 10);
      }
    },
    100,
    50
  );

  const isOpen = (status: TicketStatus) => status === TicketStatus.Open;

  const onCommentCreated = useCallback((id, comment) => {
    dispatch({ type: "addCommentToTicket", payload: { id, comment } });
  }, []);

  const onStatusChanged = useCallback(
    (id, newStatus) => {
      dispatch({
        type: "changeTicketStatusById",
        payload: { id, status: newStatus }
      });
      patientDispatch({
        type: PatientActionTypes.UpdateNotification,
        notification:
          isOpen(newStatus) ||
          ticketItemsState
            .filter(ticket => ticket.id !== id)
            .some(ticket => isOpen(ticket.status))
      });
    },
    [patientDispatch, ticketItemsState]
  );

  useEffect(() => {
    if (patientTicketsState)
      dispatch({ type: "reset", payload: { items: patientTicketsState } });
  }, [patientTicketsState]);

  useEffect(() => {
    const setFullCollection = paginationData.setFullCollection;
    setFullCollection(filterTicketsByStatus(ticketItemsState, filterStatus));
  }, [paginationData.setFullCollection, ticketItemsState, filterStatus]);

  return (
    <Card className={classes.card}>
      <div ref={scrollContainerRef} className={classes.root}>
        <div className={classes.overflowContainer}>
          <Typography variant="h6" component="h3">
            Yhteydenottopyynnöt
          </Typography>

          <Box>
            <Box mb={1}>
              {ticketItemsState.length > 0 && (
                <FormControl className={classes.selectTickets}>
                  <InputLabel>Pyynnöt</InputLabel>
                  <Select
                    input={<Input />}
                    value={filterStatus}
                    onChange={event =>
                      setFilterStatus(event.target.value as TicketStatus)
                    }
                  >
                    <MenuItem value={"any"}>Kaikki pyynnöt</MenuItem>
                    <MenuItem value={TicketStatus.Open}>Avoimet</MenuItem>
                    <MenuItem value={TicketStatus.InProgress}>
                      Oltu yhteydessä
                    </MenuItem>
                    <MenuItem value={TicketStatus.Closed}>
                      Suljettu ottamatta yhteyttä
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
            {paginationData.page.map((ticket, idx: number) => (
              <React.Fragment key={idx}>
                <TicketListItem
                  key={ticket.id}
                  item={ticket}
                  onStatusChanged={onStatusChanged}
                  onCommentCreated={onCommentCreated}
                />
                {idx < paginationData.page.length - 1 && <Divider />}
              </React.Fragment>
            ))}
            {filterTicketsByStatus(ticketItemsState, filterStatus).length ===
              0 && (
              <Alert severity="info" className={classes.alert}>
                Ei yhteydenottopyyntöjä
              </Alert>
            )}
          </Box>
        </div>
      </div>
    </Card>
  );
}
