import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { usePostPatient } from "../../api/patients";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    title: {
      "& h2": {
        color: palette.text.primary,
        fontWeight: 400
      }
    },
    content: {
      padding: "12px 24px"
    },
    textField: {
      marginBottom: "1rem",
      marginLeft: "1rem",
      minWidth: "240px"
    },
    actions: {
      padding: "16px 24px",
      "& button": {
        marginRight: "0.5rem",
        "&:last-of-type": {
          marginRight: 0
        }
      }
    }
  })
);

type AddPatientProps = {
  setReloadPatients: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AddPatient(props: AddPatientProps): JSX.Element {
  const classes = useStyles();
  const { setReloadPatients } = props;
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    phoneNumber: "",
    name: "",
    email: "",
    ssid: ""
  });

  const postPatient = usePostPatient();
  const onSubmit = () =>
    postPatient.request({
      answerId: null,
      phoneNumber: formData.phoneNumber,
      data: {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        ssid: formData.ssid,
        listName: "open"
      }
    });

  useEffect(() => {
    if (postPatient.data) {
      setStatus("success");
      setShowNotification(true);
      setReloadPatients(true);
    }
  }, [postPatient.data, setReloadPatients]);

  useEffect(() => {
    if (postPatient.error) {
      setStatus("error");
      setShowNotification(true);
    }
  }, [postPatient.error]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PersonAddIcon />}
        onClick={() => setOpen(true)}
      >
        Lisää potilas
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle className={classes.title}>Lisää potilas</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            className={classes.textField}
            label="Nimi"
            placeholder="Eino Esimerkki"
            value={formData.name || ""}
            onChange={event =>
              setFormData({
                ...formData,
                name: event.target.value as string
              })
            }
          />
          <TextField
            className={classes.textField}
            label="Sähköposti"
            placeholder="eino.esimerkki@email.com"
            value={formData.email || ""}
            onChange={event =>
              setFormData({
                ...formData,
                email: event.target.value as string
              })
            }
          />
          <TextField
            className={classes.textField}
            label="Puhelinnumero"
            placeholder="+358 12 345 6789"
            value={formData.phoneNumber || ""}
            onChange={event =>
              setFormData({
                ...formData,
                phoneNumber: event.target.value as string
              })
            }
          />
          <TextField
            className={classes.textField}
            label="Henkilötunnus"
            placeholder="010203-1234"
            value={formData.ssid || ""}
            onChange={event =>
              setFormData({
                ...formData,
                ssid: event.target.value as string
              })
            }
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={() => setOpen(false)}>
            Peruuta
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
              setOpen(false);
            }}
          >
            Lähetä
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showNotification}
        onClose={() => setShowNotification(false)}
        autoHideDuration={4000}
      >
        <Alert
          elevation={1}
          variant="filled"
          onClose={() => setShowNotification(false)}
          severity={status === "success" ? "success" : "error"}
        >
          {status === "success"
            ? `Potilas ${postPatient.data.id} lisätty!`
            : "Pahoittelut, joitain meni pieleen -- potilasta ei pystytty lisäämään."}
        </Alert>
      </Snackbar>
    </>
  );
}
