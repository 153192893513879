import React, { useEffect, useState, useCallback } from "react";

import { AuthContextValue } from "./AuthContext";
import AuthContext from "./AuthContext";
import { isAuthenticated } from "./Authentication";
import {
  ConnectionError,
  HTTPError,
  RequestError,
  setErrorListener
} from "../../utils/fetchUtils";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { Close } from "@material-ui/icons";

export default (props: any) => {
  // todo: move error notification to separate component
  const [openNotification, setOpenNotification] = useState(false);

  const [authContext, setAuthContext] = useState<AuthContextValue>({
    isAuthenticated: getIsAuthed(),
    checkAuthentication: checkAuthentication,
    setAuthentication: setAuthentication
  });

  function setAuthentication(isAuthenticated: boolean) {
    setAuthContext(state => ({
      ...state,
      isAuthenticated: isAuthenticated
    }));
  }

  function checkAuthentication() {
    setAuthContext(state => {
      const isAuthed = getIsAuthed();

      if (state.isAuthenticated !== isAuthed) {
        return {
          ...state,
          isAuthenticated: isAuthed
        };
      } else {
        return state;
      }
    });
  }

  function getIsAuthed(): boolean {
    return isAuthenticated();
  }

  const memoCheckAuth = useCallback(checkAuthentication, []);

  useEffect(() => {
    setErrorListener((err: RequestError) => {
      if (err instanceof HTTPError && err.status === 401) {
        deleteAllCookies();
        memoCheckAuth();
      } else if (err instanceof HTTPError && err.status === 500) {
        setOpenNotification(true);
      } else if (err instanceof ConnectionError) {
        setOpenNotification(true);
      }
    });

    return () => {
      setErrorListener(null as any);
    };
  }, [memoCheckAuth]);

  return (
    <AuthContext.Provider value={authContext}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openNotification}
        autoHideDuration={6000}
        onClose={() => setOpenNotification(false)}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Pahoittelut, jotain meni pieleen</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenNotification(false)}
          >
            <Close />
          </IconButton>
        ]}
      />

      {props.children}
    </AuthContext.Provider>
  );
};

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
