import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Chip,
  Checkbox,
  Paper,
  Menu,
  MenuItem,
  Button
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { fmtDateStrShort } from "../../utils/date";
import { PatientsData } from "../../api/patients";
import { useHistory } from "react-router-dom";
import { intersection } from "ramda";
import paths from "../../paths";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import IconButton from "@material-ui/core/IconButton";
import SMSModal from "../PatientCardPage/SMSModal";
import { status } from "../PatientCardPage/SEPatientInfo";

type Dict = Record<string, any>;

type TableFilters = {
  tupakointi: string | null;
  aloituskysely: string | null;
  seurantakysely: string | null;
  notifications: boolean;
};

type FilterableProps = {
  label: string;
  items: string[];
  selected: string | null;
  onChange: (value: string | null) => void;
};

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    table: {
      "& .MuiTableCell-body, & .MuiTableCell-root": {
        textAlign: "center"
      },
      // Clickable buttons
      "& .MuiIconButton-root": {
        padding: "5px"
      },
      // Soften coloring for notification icons
      "& svg.MuiSvgIcon-root": {
        opacity: 0.85
      }
    },
    tableRow: {
      textDecoration: "none"
    },
    chip: {
      marginLeft: "0.5rem",
      cursor: "pointer",
      [breakpoints.down("xs")]: {
        maxWidth: "max-content"
      }
    },
    chipsCell: {
      [breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        "& > div:last-of-type": {
          marginTop: "0.25rem"
        }
      }
    },
    desktopCell: {
      [breakpoints.down("xs")]: {
        display: "none"
      }
    },
    clickableFilter: {
      fontSize: "0.875rem"
    }
  })
);
/*
function smokingStatus({
  status,
  dateStopped
}: {
  status: string;
  dateStopped: string | null;
}) {
  switch (status) {
    case "SMOKING":
      return "Kyllä";
    case "NOT_SMOKING":
      return "Ei";
    case "STOPPED_SMOKING":
      return `Lopettanut (${
        dateStopped ? fmtDateStrShort(dateStopped) : "ei päiväystä"
      })`;
    default:
      return "";
  }
}

function surveyState({
  status,
  date
}: {
  status: string;
  date: string | null;
}) {
  switch (status) {
    case "NOT_STARTED":
      return "-";
    case "LINK_SENT":
      return `Linkki lähetetty (${
        date ? fmtDateStrShort(date) : "ei päiväystä"
      })`;
    case "REMINDER_SENT":
      return `Muistutus lähetetty (${
        date ? fmtDateStrShort(date) : "ei päiväystä"
      })`;
    case "ANSWERED":
      return `Kyselyyn vastattu (${
        date ? fmtDateStrShort(date) : "ei päiväystä"
      })`;
  }
}
*/
function Filterable({ label, items, selected, onChange }: FilterableProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(open => !open);
  };

  return (
    <div>
      <Button
        className={classes.clickableFilter}
        variant="text"
        color="primary"
        onClick={handleClick}
      >
        {label}
      </Button>
      {selected && (
        <div>
          <Chip size="small" label={selected} onDelete={() => onChange(null)} />
        </div>
      )}
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={open}
        onClose={handleClick}
        keepMounted
      >
        {items.map((item, idx) => (
          <MenuItem
            key={`filterable-${idx}`}
            className={classes.clickableFilter}
            onClick={() => {
              onChange(item);
              setOpen(false);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const filterOptions: Record<string, any> = {
  tupakointi: {
    NOT_SMOKING: "Ei",
    SMOKING: "Kyllä",
    STOPPED_SMOKING: "Lopettanut"
  },
  kysely: {
    NOT_STARTED: "-",
    LINK_SENT: "Linkki lähetetty",
    REMINDER_SENT: "Muistutus lähetetty",
    VISITED: "Linkki avattu",
    ANSWERED: "Kyselyyn vastattu"
  }
};

function isFiltered(
  key: string,
  filter: string | null,
  status?: string | null
) {
  if (!filter) return true;
  return status ? filterOptions[key][status] === filter : false;
}

function applyFilters(filters: TableFilters, item: PatientsData) {
  // If all filters are falsy, then pass through
  if (Object.values(filters).every(x => !x)) return true;

  const checkSmoking = (value?: string) =>
    isFiltered("tupakointi", filters.tupakointi, value);

  const checkStartSurvey = (value?: string) =>
    isFiltered("kysely", filters.aloituskysely, value);

  const checkFollowupSurvey = (value?: string) =>
    isFiltered("kysely", filters.seurantakysely, value);

  const checkNotification = (value: boolean) =>
    filters.notifications ? filters.notifications === value : true;

  return (
    checkSmoking(item.smokexit?.smoking.status) &&
    checkStartSurvey(item.smokexit?.startSurveyStatus.status) &&
    checkFollowupSurvey(item.smokexit?.followupSurveyStatus.status) &&
    checkNotification(item.notification)
  );
}

function ClickableTableCell(props: { children: React.ReactNode; id: number }) {
  const history = useHistory();

  return (
    <TableCell onClick={() => history.push(`${paths.patientCard}/${props.id}`)}>
      {props.children}
    </TableCell>
  );
}

function allVisibleItemsSelected(
  allRows: PatientsData[],
  checkboxes: Record<number, boolean>
) {
  const rowIndices = allRows.map(row => row.id);
  const checkedIndices = Object.keys(checkboxes)
    .filter(idx => checkboxes[parseInt(idx)])
    .map(value => parseInt(value));

  return (
    intersection(rowIndices, checkedIndices).length === rowIndices.length &&
    rowIndices.length === checkedIndices.length
  );
}

export default function SEPatientList(props: any) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage?.getItem("rowsPerPage") ?? "10", 10) || 10
  );
  const [allRows, setAllRows] = useState<PatientsData[]>(props.rows);
  const [visibleRows, setVisibleRows] = useState<PatientsData[]>(props.rows);
  const [checkboxes, setCheckboxes] = useState<Record<number, boolean>>({});
  const [checkboxHeading, setCheckboxHeading] = useState<Record<string, any>>({
    indeterminate: false,
    checked: false
  });
  const initialFilters = {
    tupakointi: null,
    aloituskysely: null,
    seurantakysely: null,
    notifications: false
  };

  const [filters, setFilters] = useState<TableFilters>(initialFilters);

  // Reset to show all patients if data changes (i.e. new patient is added)
  if (props.rows !== allRows) {
    setAllRows(props.rows);
    setFilters(initialFilters);
    setVisibleRows(props.rows);
  }

  const updateFilters = (key: string) => (value: boolean | string | null) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);

    const updatedVisibleRows = props.rows.filter((row: PatientsData) =>
      applyFilters(updatedFilters, row)
    );
    setVisibleRows(updatedVisibleRows);
    return updatedVisibleRows;
  };

  const updateCheckboxHeading = (
    rows: PatientsData[],
    checked: Record<number, boolean>
  ) => {
    const checkedEntries = Object.values(checked).filter(x => x);
    const indeterminate = checkedEntries
      ? !allVisibleItemsSelected(rows, checked)
      : false;

    setCheckboxHeading({
      checked: !indeterminate,
      indeterminate: checkedEntries.length > 0 ? indeterminate : false
    });
  };

  const updateFiltersAndCheckboxHeading = (filterKey: string) => (
    filterValue: string | null | boolean
  ) => {
    const updatedRows = updateFilters(filterKey)(filterValue);
    updateCheckboxHeading(updatedRows, checkboxes);
  };

  return (
    <TableContainer component={Paper} {...props}>
      <Table
        className={classes.table}
        size="small"
        aria-label="potilaslista"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell>
              {visibleRows.length > 0 && (
                <Checkbox
                  checked={checkboxHeading.checked}
                  indeterminate={checkboxHeading.indeterminate}
                  onChange={event => {
                    const checked = event.target.checked;
                    setCheckboxHeading({ indeterminate: false, checked });

                    // Handle individual checkbox updates also
                    if (checked) {
                      const kvPairs = visibleRows.map(row => [row.id, true]);
                      setCheckboxes(Object.fromEntries(kvPairs));
                    } else {
                      setCheckboxes({});
                    }
                  }}
                />
              )}
            </TableCell>
            <TableCell>Potilas ID</TableCell>
            <TableCell>
              <Filterable
                label="Tupakointi"
                items={Object.values(filterOptions.tupakointi)}
                selected={filters.tupakointi}
                onChange={updateFiltersAndCheckboxHeading("tupakointi")}
              />
            </TableCell>
            <TableCell>Aloituskysely</TableCell>
            <TableCell>Seuranta</TableCell>
            <TableCell>Lääkehoito</TableCell>
            <TableCell>Puhelut</TableCell>
            <TableCell>
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  // Reset everything
                  setPage(0);
                  updateFiltersAndCheckboxHeading("notifications")(
                    !filters.notifications
                  );
                }}
              >
                {filters.notifications ? (
                  <NotificationsIcon />
                ) : (
                  <NotificationsNoneIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell className={classes.desktopCell}>
              Viimeisin vastaus
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: PatientsData, idx: number) => (
              <TableRow className={classes.tableRow} hover key={row.id}>
                {/* Valittu? */}
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={checkboxes[row.id] || false}
                    onChange={event => {
                      const updated = {
                        ...checkboxes,
                        [row.id]: event.target.checked
                      };

                      setCheckboxes(updated);
                      // Update heading as well
                      updateCheckboxHeading(visibleRows, updated);
                    }}
                  />
                </TableCell>
                {/* Potilas ID */}
                <ClickableTableCell id={row.id}>
                  {row?.data?.name ?? "-"}
                </ClickableTableCell>
                {/* Tupakointi */}
                <ClickableTableCell id={row.id}>
                  {row.data?.smokeInfo?.isSmoking ? "Kyllä" : "Ei"}
                </ClickableTableCell>
                {/* Aloituskysely */}
                <ClickableTableCell id={row.id}>
                  {status(row?.smokexit?.startSurveyStatus)}
                </ClickableTableCell>
                {/* Seuranta */}
                <ClickableTableCell id={row.id}>
                  {row.data?.smokeInfo?.botMonitoring ? "Kyllä" : "Ei"}
                </ClickableTableCell>
                {/* Lääkehoito */}
                <ClickableTableCell id={row.id}>
                  {row.data?.smokeInfo?.isTreated ? "Kyllä" : "Ei"}
                </ClickableTableCell>
                {/* Puhelut */}
                <ClickableTableCell id={row.id}>
                  {row.data?.smokeInfo?.callsAmount
                    ? row.data?.smokeInfo?.callsAmount
                    : 0}
                </ClickableTableCell>
                {/* Notifikaatio-kuvake */}
                <ClickableTableCell id={row.id}>
                  {row.notification && <NotificationsIcon />}
                </ClickableTableCell>
                {/* Viimeisin vastaus */}
                <ClickableTableCell id={row.id}>
                  {row.smokexit?.latestResponse &&
                    `${row.smokexit.latestResponse.surveyName} (${
                      row.smokexit.latestResponse?.date
                        ? fmtDateStrShort(row.smokexit.latestResponse.date)
                        : "ei päiväystä"
                    })`}
                </ClickableTableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {visibleRows.length > 0 && (
              <td className="MuiTableCell-root MuiTableCell-footer MuiTablePagination-root MuiTableCell-sizeSmall">
                <SMSModal
                  mass
                  patientIds={Object.keys(checkboxes)
                    .filter(id => checkboxes[parseInt(id)])
                    .map(id => parseInt(id))}
                  ButtonComponent={({ onClick }: any) => (
                    <Button
                      onClick={onClick}
                      disabled={
                        Object.values(checkboxes).filter(x => x).length === 0
                      }
                      color="primary"
                      size="small"
                    >
                      Tekstiviesti valituille
                    </Button>
                  )}
                />
              </td>
            )}
            <TablePagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={visibleRows.length}
              onChangePage={(_event: any, page: number) => setPage(page)}
              onChangeRowsPerPage={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                localStorage?.setItem("rowsPerPage", event.target.value);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
