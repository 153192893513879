import React from "react";
import { Box, Chip, Typography } from "@material-ui/core";
import UserStatus from "./UserStatus";
import Dropdown from "../../components/Dropdown";
import { parseDateStr, lastPatientUpdate } from "../../utils/date";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { isSmokeExit } from "../../config";
import NotificationsIcon from "@material-ui/icons/Notifications";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "1rem"
    },
    topInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& h1": {
        marginBottom: "0.25rem",
        display: "inherit",
        "& span": {
          display: "inherit",
          marginLeft: "0.75rem",
          "& svg": {
            opacity: 0.85,
            fill: "#0D294A",
            borderRadius: "8px",
            padding: "3px 0"
          }
        }
      }
    },
    staffContainer: {
      margin: "1.5rem 0 0.5rem 0",
      "& div.MuiGrid-item": {
        margin: "auto"
      }
    },
    staffStatuses: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      "& > div": {
        margin: "0 1rem"
      }
    },
    dropdown: {
      marginTop: "0.5rem",
      marginBottom: "0.25rem"
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row"
    },
    lastUpdated: {
      color: "#555"
    },
    actions: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
      "& div.MuiGrid-root": {
        margin: 0
      },
      "& button": {
        width: "100%"
      }
    }
  })
);

export default function BasicInfo({
  userState,
  patient,
  nurses,
  staff,
  updatePatient
}: any) {
  const classes = useStyles();

  const lastStaffUpdate = lastPatientUpdate(
    patient.clinicianLastUpdate && parseDateStr(patient.clinicianLastUpdate),
    patient.nurseLastUpdate && parseDateStr(patient.nurseLastUpdate)
  );
  const listItems = [
    { listName: "open", listLabel: "Soitettavat potilaat" },
    { listName: "callMonitoring", listLabel: "Seurannassa olevat potilaat" },
    { listName: "botMonitoring", listLabel: "Kutsutut potilaat" },
    { listName: "closed", listLabel: "Suljetut potilaat" }
  ];

  const getListLabel = () => {
    if (patient.data?.listName) {
      const item = listItems.find(
        item => item.listName === patient.data?.listName
      );
      return item?.listLabel;
    }
    return "Soitettavat potilaat";
  };

  const getSelectedItem = () => {
    if (patient.data?.listName) {
      return listItems.findIndex(
        item => item.listName === patient.data?.listName
      );
    }
    return 0;
  };
  return (
    <section className={classes.root}>
      <Box className={classes.topInfo}>
        <Typography variant="h5" component="h1">
          Potilaskortti {patient.id}
          {isSmokeExit && patient.notification && (
            <span>
              <NotificationsIcon />
            </span>
          )}
        </Typography>
        <Typography>Luotu {patient.createdAt}</Typography>
        {lastStaffUpdate && (
          <Typography variant="body2" className={classes.lastUpdated}>
            (päivitetty {lastStaffUpdate[1]})
          </Typography>
        )}

        <Box className={classes.staffContainer}>
          <Box className={classes.staffStatuses}>
            {isSmokeExit && <Chip label={getListLabel()} />}
            {staff.nurse && staff.nurse !== "" && !isSmokeExit && (
              <UserStatus
                role={"nurse"}
                name={staff.nurse}
                status={patient.nurseStatus}
              />
            )}
            {staff.doctor && staff.doctor !== "" && !isSmokeExit && (
              <UserStatus
                role={"doctor"}
                name={staff.doctor}
                status={patient.clinicianStatus}
              />
            )}
          </Box>
          <Box className={classes.actions}>
            <Dropdown
              className={classes.dropdown}
              label={"Siirrä toiselle listalle"}
              onIndexChanged={(idx: number) => {
                updatePatient({
                  data: { listName: `${listItems[idx].listName}` }
                });
              }}
              menuItems={listItems}
              getMenuItemProps={(item: any) => ({
                value: item.listName,
                label: item.listLabel
              })}
              selectedItem={getSelectedItem()}
            />
          </Box>
        </Box>
      </Box>
    </section>
  );
}
