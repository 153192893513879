import React, { useState, useEffect, useRef } from "react";
import { Input, IconButton, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import useSocket from "../../utils/socketHook";
import { usePatientState } from "../../api/PatientProvider";
import Loading from "../../components/Loading";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    heading: {
      marginBottom: "1rem"
    },
    chat: {
      position: "relative",
      width: "100%",
      height: "30rem",
      border: "1px solid #eee",
      borderRadius: "4px"
    },
    chatFooter: {
      width: "100%",
      position: "absolute",
      bottom: "20px",
      left: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    formRoot: {
      width: "90%",
      "& > div": {
        width: "100%"
      }
    },
    messages: {
      height: "calc(100% - 100px)",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll"
    },
    message: {
      margin: "0.6rem 1rem",
      "& div.author": {
        fontWeight: 600,
        marginBottom: "0.5rem"
      },
      "& div.message": {}
    }
  })
);

export default function LiveDiscussion(props: any) {
  const patientState = usePatientState();
  const classes = useStyles();
  const socketPath = "/";
  const [textInput, setTextInput] = useState("");
  const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout | null>(null);
  const [messages, setMessages] = useState<Record<string, any>[]>([]);
  const [isChatInitialized, setChatInitialized] = useState(false);
  const patientId = patientState.patient?.id;
  const { socket } = useSocket(socketPath, patientId);
  const messagesElRef = useRef<any>(null);

  // Initialization (get history)
  useEffect(() => {
    if (socket && !isChatInitialized) {
      setChatInitialized(true);
      socket.emit("getHistory", patientId, (messages: any) => {
        setMessages(messages);
        scrollToBottom();
      });
    }
  }, [isChatInitialized, socket, patientId]);

  // Message updates
  useEffect(() => {
    if (socket) {
      socket.on("newMessage", (newMessage: any, cb) => {
        if (newMessage) {
          setMessages(messages => [...messages, newMessage]);
          scrollToBottom();
          cb(newMessage.id);
        }
      });

      socket.on("userTyping", (payload: any) => {
        if (payload) {
          const isTyping = payload.isTyping ? " kirjoittaa" : " ei kirjoita";
          const typing = {
            username: "Typing...",
            text: payload.username + isTyping
          };
          setMessages(messages => [...messages, typing]);
          scrollToBottom();
        }
      });

      socket.on("userConnected", (payload: any) => {
        if (payload) {
          const typing = {
            username: "Connected...",
            text: payload.username + " liittyi"
          };
          setMessages(messages => [...messages, typing]);
          scrollToBottom();
        }
      });

      socket.on("userDisconnected", (payload: any) => {
        if (payload) {
          const typing = {
            username: "Connected...",
            text: payload.username + " poistui"
          };
          setMessages(messages => [...messages, typing]);
          scrollToBottom();
        }
      });

      socket.on("sessionEnded", (payload: any) => {
        if (payload) {
          const typing = { username: "Session ended...", text: payload.reason };
          setMessages(messages => [...messages, typing]);
          scrollToBottom();
        }
      });

      socket.on("updatedMessage", (payload: any) => {
        if (payload) {
          const message = {
            username: "UPDATE",
            text: `Received: ${payload.received}, Seen: ${payload.seen}`
          };
          setMessages(messages => [...messages, message]);
          scrollToBottom();
        }
      });

      socket.on("connectedUsers", (payload: any) => {
        if (payload) {
          const message = {
            username: "CONNECTED USERS: ",
            text: JSON.stringify(payload)
          };
          setMessages(messages => [...messages, message]);
          scrollToBottom();
        }
      });

      socket.on("serverError", (payload: any) => {
        if (payload) {
          const error = { username: "ERROR", text: payload.reason };
          setMessages(messages => [...messages, error]);
          scrollToBottom();
        }
      });
    }
  }, [socket]);

  const sendMessage = (message: string): void => {
    // Send typing false event always before sending a message
    if (typingTimer != null) {
      clearTimeout(typingTimer);
      setTypingTimer(null);
    }
    sendTyping(false);

    if (!socket) return;

    if (message === "leave") socket.emit("leave", { chatId: patientId });
    else if (message === "seen")
      socket.emit("messagesSeen", { chatId: patientId });
    else if (message === "users")
      socket.emit("getUsers", { chatId: patientId });
    else
      socket.emit("sendMessage", {
        id: new Date().getTime().toString(),
        chatId: patientId,
        text: message
      });
  };

  const submitForm = () => {
    if (socket && patientId) {
      sendMessage(textInput);
      setTextInput("");
    } else {
      console.error(
        `Can't submit message (socket: ${socket}, patientId: ${patientId})`
      );
    }
  };

  // Scrolls to bottom of chat container,
  // called when new message or entire history received
  const scrollToBottom = () => {
    if (!messagesElRef?.current) return;
    const div = messagesElRef.current;

    div.scrollTop = div.scrollHeight;
  };

  const sendTyping = (isTyping: boolean) =>
    socket &&
    socket.emit("sendTyping", {
      chatId: patientId,
      isTyping
    });

  const handleTyping = () => {
    // If timer is set reset it
    if (typingTimer != null) {
      clearTimeout(typingTimer);
      // Otherwise send typing started
    } else {
      sendTyping(true);
    }
    setTypingTimer(
      setTimeout(() => {
        sendTyping(false);
        setTypingTimer(null);
      }, 5000)
    );
  };

  if (!patientId) return <Redirect to="/" />;
  return (
    <React.Fragment>
      <Typography variant="h6" component="h3" className={classes.heading}>
        Kuura Live
      </Typography>
      <div className={`livechat ${classes.chat}`}>
        {!isChatInitialized ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div
              ref={messagesElRef}
              className={`livechat-messages ${classes.messages}`}
            >
              {messages.map((msg, idx) => (
                <div key={idx} className={classes.message}>
                  <div className="author">{msg.username}</div>
                  <div className="message">{msg.text}</div>
                </div>
              ))}
            </div>
            <div className={`livechat-footer ${classes.chatFooter}`}>
              <form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  submitForm();
                }}
                className={classes.formRoot}
              >
                <Input
                  type={"text"}
                  value={textInput}
                  autoFocus={false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTextInput(event.target.value);
                    handleTyping();
                  }}
                  placeholder={"Viestisi"}
                  endAdornment={
                    <IconButton
                      color="primary"
                      aria-label="lähetä"
                      onClick={submitForm}
                    >
                      <SendIcon />
                    </IconButton>
                  }
                />
              </form>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
