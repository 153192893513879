import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import {
  ThemeProvider,
  Theme,
  makeStyles,
  createStyles
} from "@material-ui/core/styles";
import theme from "./theme";
import paths from "./paths";
import DateFnsUtils from "@date-io/date-fns";
import { fi } from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// Routing
import AuthorizedOnlyRoute from "./components/AuthorizedOnlyRoute";
import AnonymousOnlyRoute from "./components/AnonymousOnlyRoute";
import AuthProvider from "./api/Authorization/AuthProvider";

// Pages
import NewPatientPage from "./pages/NewPatientPage";
import PatientsPage from "./pages/PatientsPage";
import PatientCardPage from "./pages/PatientCardPage";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import InfoPage from "./pages/InfoPage";

// Other components
import Header from "./components/Header";
import { UserProvider } from "./api/UserProvider";
import { PatientProvider } from "./api/PatientProvider";
import { isSmokeExit } from "./config";

const useStyles = makeStyles(({ palette, mixins }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "70px",
      paddingBottom: "70px"
    }
  })
);

const authorizedRoutes = {
  [paths.root]: ({ location }: any) => (
    <Redirect
      to={{
        ...location,
        pathname: isSmokeExit ? paths.patients : paths.newPatient
      }}
    />
  ),
  [paths.index]: ({ location }: any) => (
    <Redirect
      to={{
        ...location,
        pathname: isSmokeExit ? paths.patients : paths.newPatient
      }}
    />
  ),
  [isSmokeExit ? paths.smokeExitSurvey : paths.survey]: NewPatientPage,
  [paths.patients]: PatientsPage,
  [paths.patientCard]: PatientCardPage,
  [paths.patientCardForId]: PatientCardPage
};

function App() {
  const classes = useStyles();

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <PatientProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fi}>
              <Router>
                <Switch>
                  {isSmokeExit && (
                    <Route path={paths.information} component={InfoPage} />
                  )}
                  <Route path={paths.links} component={NewPatientPage} />
                  <AnonymousOnlyRoute
                    exact
                    path={paths.login}
                    component={LoginPage}
                  />
                  <AuthorizedOnlyRoute>
                    <UserProvider>
                      <Header />
                      <div className={classes.container}>
                        <Switch>
                          {Object.keys(authorizedRoutes).map((item, idx) => (
                            <Route
                              key={idx}
                              exact
                              path={item}
                              component={authorizedRoutes[item]}
                            />
                          ))}
                          <Route component={NotFoundPage} />
                        </Switch>
                      </div>
                    </UserProvider>
                  </AuthorizedOnlyRoute>
                  <Route component={NotFoundPage} />
                </Switch>
              </Router>
            </MuiPickersUtilsProvider>
          </PatientProvider>
        </AuthProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
