import { makeRequestHook } from "../utils/requestHook";
import { get, UrlParams } from "../utils/httpUtils";

const endpoint = "/user";

export type UserData = {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  usertype: string;
  admin: boolean;
};

const initialValue: UserData = {
  id: "",
  username: "",
  firstname: "",
  lastname: "",
  usertype: "",
  admin: false
};

function getUser(): Promise<UserData> {
  return get<any>(endpoint, undefined);
}

export function useGetUser() {
  return makeRequestHook<UserData, UrlParams>(getUser)(initialValue);
}
