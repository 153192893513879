import React from "react";
import { Box, Typography, Switch, Card } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  usePatientState,
  usePatientDispatch,
  ActionTypes
} from "../../api/PatientProvider";
import { fmtStdDateStr } from "../../utils/date";
import { usePutPatient, PatientData } from "../../api/patient";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    itemsContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "0.75rem",
      marginBottom: "1rem",
      "& > :first-child": {
        marginBottom: "0.25rem"
      }
    },
    item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "0.75rem"
    },
    itemTitle: {
      maxWidth: "150px",
      width: "100%",
      color: "#555"
    },
    itemValueBox: {
      textAlign: "left",
      maxWidth: "300px",
      width: "100%",
      "& p": {
        margin: 0,
        paddingLeft: "0.5rem"
      }
    },
    card: {
      padding: "1rem"
    }
  })
);

function Toggle() {
  const patient = usePatientState().patient;
  const dispatch = usePatientDispatch();
  const putPatient = usePutPatient(patient?.id || -1);
  const status = patient?.nurseStatus;

  return (
    <Switch
      color="primary"
      checked={status === 1 ? true : false}
      onChange={() => {
        const changedStatus = status !== undefined ? 1 - status : 0; // 1 -> 0, 1 -> 0
        putPatient.request({ id: patient?.id, nurseStatus: changedStatus });
        dispatch({
          type: ActionTypes.UpdatePatient,
          patient: { ...patient, nurseStatus: changedStatus } as PatientData
        });
      }}
    />
  );
}

const age = (val?: number) =>
  val === undefined ? "Ei tietoa" : `${val} askivuotta`;
const identity = (val?: any) => {
  if (val === undefined) return "Ei tietoa";
  if (val === 1 || val === "1") return "Kyllä";
  if (val === 0 || val === "0") return "Ei";
  return val;
};
const date = (val: string) => {
  try {
    return fmtStdDateStr(val, false);
  } catch (err) {
    return "-";
  }
};
export const status = (val: any) => {
  switch (val?.status) {
    case "NOT_STARTED":
      return "-";
    case "LINK_SENT":
      return "Linkki lähetetty " + date(val?.date);
    case "REMINDER_SENT":
      return "Muistutus lähetetty " + date(val?.date);
    case "ANSWERED":
      return "Kyselyyn vastattu " + date(val?.date);
    case "VISITED":
      return "Linkki avattu " + date(val?.date);
    default:
      return "";
  }
};

// Resolves val to either itself or to a paragraph containing val
const resolveComponent = (val: any) =>
  typeof val === "string" || typeof val === "number" ? <p>{val}</p> : val;

export default function SEPatientInfo() {
  const classes = useStyles();
  const patient = usePatientState().patient;
  const patientNodes = patient?.selectedNodes;
  const smokexitNodes = patient?.smokexit;
  const items = [
    ["Aloituskysely", status, smokexitNodes?.startSurveyStatus],
    ["Seurantakysely", status, smokexitNodes?.followupSurveyStatus],
    ["Ensikontakti", date, smokexitNodes?.startSurveyDate],
    ["Tupakointi", identity, patientNodes?.status?.answer],
    ["Tupakointihistoria", age, patientNodes?.askivuodet?.answer],
    ["Halukkuus lopettaa", identity, patientNodes?.halukkuus?.answer],
    ["Lopettanut", identity, patientNodes?.lopetus?.answer],
    ["Lopetuspäivä", date, patientNodes?.lopetuspvm?.answer],
    ["Vieroitushoito", identity, patientNodes?.resepti?.answer]
  ];

  return (
    <Box>
      <Card className={classes.card}>
        <Typography variant="h6" component="h2">
          Kyselyiden yhteenveto
        </Typography>
        <Box className={classes.itemsContainer}>
          <Box className={classes.item}>
            <Typography className={classes.itemTitle}>
              Potilas seurannassa
            </Typography>
            <Box className={classes.itemValueBox}>
              <Toggle />
            </Box>
          </Box>
          {items.map(([title, fn, val]: any, idx: number) => (
            <Box key={title} className={classes.item}>
              <Typography className={classes.itemTitle}>{title}</Typography>
              <Box className={classes.itemValueBox}>
                {resolveComponent(fn(val))}
              </Box>
            </Box>
          ))}
        </Box>
      </Card>
    </Box>
  );
}
