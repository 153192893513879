import { makeRequestHook } from "../utils/requestHook";
import { post } from "../utils/httpUtils";

import { QuestionData } from "./getQuestion";

const endpoint = "/v1/frontend/getLink";

const fullEndpoint = (linkUuid: string) => `${endpoint}/${linkUuid}`;

function postGetLink(uuid: string) {
  return (): Promise<QuestionData> => {
    return post<any>(fullEndpoint(uuid), undefined, undefined);
  };
}

export function useGetLink(uuid: string | undefined) {
  return makeRequestHook<any, Record<string, any>>(postGetLink(uuid || ""))(
    undefined
  );
}
