import React, { useContext, useEffect, useReducer } from "react";
import { UserData, useGetUser } from "./user";

export enum ActionTypes {
  UpdateUser,
  UpdatePatient
}
type Action = {
  type: ActionTypes;
  user?: UserData;
};
export type Dispatch = (action: Action) => void;
export type State = {
  user: UserData | null;
};
type ProviderProps = { children: React.ReactNode };

const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.UpdateUser:
      return action.user ? { ...state, user: action.user } : state;
    default:
      return state;
  }
}

export function useUserState() {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be used inside UserContext");
  }
  return context;
}

export function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (!context) {
    throw new Error("useUserDispatch must be used inside UserContext");
  }
  return context;
}

export function UserProvider({ children }: ProviderProps) {
  const { request, data } = useGetUser();
  const [state, dispatch] = useReducer(userReducer, {
    user: null
  });

  useEffect(() => {
    request({});
  }, [request]);

  useEffect(() => {
    if (data) {
      dispatch({ type: ActionTypes.UpdateUser, user: data });
    }
  }, [data]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}
