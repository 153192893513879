import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Switch,
  TextField,
  Button,
  IconButton
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  ActionTypes,
  usePatientDispatch,
  usePatientState
} from "../../api/PatientProvider";
import { usePutPatient, PatientData } from "../../api/patient";
import { DatePicker } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    itemsContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "0.75rem",
      marginBottom: "1rem"
    },
    item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "0.75rem"
    },
    itemTitle: {
      maxWidth: "150px",
      width: "100%",
      color: "#555"
    },
    itemValueBox: {
      textAlign: "left",
      maxWidth: "400px",
      width: "100%",
      "& p": {
        margin: 0,
        paddingLeft: "0.5rem"
      }
    },
    card: {
      padding: "1rem"
    },
    datePicker: {
      maxWidth: "120px"
    },
    textField: {
      width: "230px",
      marginRight: "0.5rem"
    },
    textFieldInactive: {
      width: "230px",
      display: "inline-block",
    },
    saveButton: {
      marginLeft: "0.5rem"
    },
    iconButton: {
      color: "black"
    }
  })
);

function Toggle(isChecked: any, id: string) {
  const patient = usePatientState().patient;
  const dispatch = usePatientDispatch();
  const putPatient = usePutPatient(patient?.id || -1);
  const data: Record<string, any> = {};
  Object.assign(data, patient?.data);
  return (
    <Switch
      color="primary"
      checked={isChecked ? isChecked : false}
      onChange={() => {
        if (!patient?.data?.smokeInfo) data.smokeInfo = {};
        data.smokeInfo[id] = !isChecked;
        putPatient.request({ id: patient?.id, data: data });
        dispatch({
          type: ActionTypes.UpdatePatient,
          patient: { ...patient, data: data } as PatientData
        });
      }}
    />
  );
}

function Date(val: any, id: string) {
  const classes = useStyles();
  const patient = usePatientState().patient;
  const dispatch = usePatientDispatch();
  const putPatient = usePutPatient(patient?.id || -1);
  const data: Record<string, any> = {};
  Object.assign(data, patient?.data);

  return (
    <DatePicker
      className={classes.datePicker}
      InputProps={{
        endAdornment: <DateRangeIcon />,
        disableUnderline: true
      }}
      disableToolbar
      emptyLabel="-"
      autoOk={true}
      variant="inline"
      format="dd.MM.yyyy"
      value={val ? val : null}
      onChange={date => {
        if (!patient?.data?.smokeInfo) data.smokeInfo = {};
        data.smokeInfo[id] = date;
        putPatient.request({ id: patient?.id, data: data });
        dispatch({
          type: ActionTypes.UpdatePatient,
          patient: { ...patient, data: data } as PatientData
        });
      }}
    />
  );
}

function Text(val: any, id: string) {
  const classes = useStyles();
  const patient = usePatientState().patient;
  const dispatch = usePatientDispatch();
  const putPatient = usePutPatient(patient?.id || -1);
  const [editText, setEditText] = useState(false);
  const [text, setText] = useState(
    id === "treatmentText"
      ? patient?.data?.smokeInfo?.treatmentText
      : patient?.data?.[id]
  );
  const data: Record<string, any> = {};
  Object.assign(data, patient?.data);
  const saveData = () => {
    if (!patient?.data?.smokeInfo) data.smokeInfo = {};
    if (id === "treatmentText") data.smokeInfo[id] = text;
    else data[id] = text;
    putPatient.request({ id: patient?.id, data: data });
    dispatch({
      type: ActionTypes.UpdatePatient,
      patient: { ...patient, data: data } as PatientData
    });
  };

  return (
    <>
      {editText ? (
        <TextField
          key={id}
          className={classes.textField}
          value={text || ""}
          onChange={event => setText(event.target.value as string)}
        />
      ) : (
        <span className={classes.textFieldInactive}>{text || "-"}</span>
      )}
      {editText ? (
        <Button
          className={classes.saveButton}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            setEditText(false);
            saveData();
          }}
        >
          Tallenna
        </Button>
      ) : (
        <IconButton onClick={() => setEditText(true)}>
          <EditIcon className={classes.iconButton} fontSize="small" />
        </IconButton>
      )}
    </>
  );
}

function Number(val: any, id: string) {
  const classes = useStyles();
  const patient = usePatientState().patient;
  const dispatch = usePatientDispatch();
  const putPatient = usePutPatient(patient?.id || -1);
  const data: Record<string, any> = {};
  Object.assign(data, patient?.data);

  return (
    <>
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          if (!patient?.data?.smokeInfo) data.smokeInfo = {};
          if (!isNaN(patient?.data?.smokeInfo[id])) {
            if (patient?.data?.smokeInfo[id] > 0) {
              --data.smokeInfo[id];
              putPatient.request({ id: patient?.id, data: data });
              dispatch({
                type: ActionTypes.UpdatePatient,
                patient: { ...patient, data: data } as PatientData
              });
            }
          }
        }}
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
      {val}
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          if (!patient?.data?.smokeInfo) data.smokeInfo = {};
          if (!isNaN(patient?.data?.smokeInfo[id])) {
            ++data.smokeInfo[id];
          } else {
            data.smokeInfo[id] = 1;
          }
          putPatient.request({ id: patient?.id, data: data });
          dispatch({
            type: ActionTypes.UpdatePatient,
            patient: { ...patient, data: data } as PatientData
          });
        }}
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </>
  );
}

function ValComponent(type: string | undefined, val: any, id: string) {
  switch (type) {
    case "text":
      return Text(val, id);
    case "number":
      return Number(val, id);
    case "switch":
      return Toggle(val, id);
    case "date":
      return Date(val, id);
    case "treatment":
      return (
        <>
          {Toggle(val, id)} {Text(val, "treatmentText")}
        </>
      );
    default:
      return val !== undefined ? <p>{val}</p> : <p>-</p>;
  }
}

export default function PatientDataInfo(): JSX.Element {
  const classes = useStyles();
  const patient = usePatientState().patient;
  const items = [
    ["Potilaan ID", patient?.data?.name, "text", "name"],
    ["Sähköposti", patient?.data?.email, "text", "email"],
    ["Puhelinnumero", patient?.data?.phoneNumber, "text", "phoneNumber"],
    ["Henkilötunnus", patient?.data?.ssid, "text", "ssid"],
    ["Tupakoi", patient?.data?.smokeInfo?.isSmoking, "switch", "isSmoking"],
    ["Aloituspvm", patient?.data?.smokeInfo?.startDate, "date", "startDate"],
    [
      "Lääkehoito",
      patient?.data?.smokeInfo?.isTreated,
      "treatment",
      "isTreated"
    ],
    [
      "Soitetut puhelut",
      patient?.data?.smokeInfo?.callsAmount || 0,
      "number",
      "callsAmount"
    ],
    [
      "Sovittu soittopäivä",
      patient?.data?.smokeInfo?.callMonitoring,
      "date",
      "callMonitoring"
    ],
    [
      "Seuranta",
      patient?.data?.smokeInfo?.botMonitoring,
      "switch",
      "botMonitoring"
    ],
    ["Lopetuspäivä", patient?.data?.smokeInfo?.endDate, "date", "endDate"]
  ];
  return (
    <Box>
      <Card className={classes.card}>
        <Typography variant="h6" component="h2">
          Potilaan tiedot
        </Typography>
        <Box className={classes.itemsContainer}>
          {items.map(([title, val, type, id]: any, idx: number) => {
            return (
              <Box key={title} className={classes.item}>
                <Typography className={classes.itemTitle}>{title}</Typography>
                <Box className={classes.itemValueBox}>
                  {ValComponent(type, val, id)}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Card>
    </Box>
  );
}
