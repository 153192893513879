import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Button,
  MenuItem,
  Input,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { equals } from "ramda";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useGetAllSurveys } from "../../api/survey";
import { usePatientState } from "../../api/PatientProvider";
import {
  usePostSMS,
  useGetSMSTemplates,
  NewSMS,
  SMS,
  NewSMSMultiple,
  usePostMultipleSMS
} from "../../api/sms";
import { useSEDispatch, ActionTypes as SEActionTypes } from "./SEProvider";

const useStyles = makeStyles(({ palette, spacing, shadows }: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      backgroundColor: palette.background.paper,
      borderRadius: "4px",
      border: "none",
      boxShadow: shadows[5],
      padding: spacing(2, 4, 3)
    },
    title: {
      "& h2": {
        color: palette.text.primary,
        fontWeight: 400
      }
    },
    content: {
      padding: "12px 24px"
    },
    formControl: {
      minWidth: 200
    },
    textField: {
      marginBottom: "1rem",
      marginTop: "1.25rem"
    },
    checkbox: {
      marginTop: "1rem"
    },
    actions: {
      padding: "16px 24px",
      "& button": {
        marginRight: "0.5rem",
        "&:last-of-type": {
          marginRight: 0
        }
      }
    },
    button: {
      marginTop: "1rem",
      padding: 0
    },
    sendButton: {
      border: 0
    }
  })
);

type SurveyData = {
  id: any;
  name: any;
  url: any;
};

type SubmissionStatus = "unsent" | "error" | "success";

type PatientModalProps = {
  formData: NewSMS;
  setFormData: (value: any) => void;
  onSubmit: () => void;
  button?: undefined;
  status: SubmissionStatus;
  showNotification: boolean;
  onNotificationClose: () => void;
};

type MassModalProps = {
  formData: NewSMSMultiple;
  setFormData: (value: any) => void;
  onSubmit: () => void;
  button: any;
  status: SubmissionStatus;
  showNotification: boolean;
  onNotificationClose: () => void;
};

type ModalProps = PatientModalProps | MassModalProps;

function Modal(props: ModalProps) {
  const {
    formData,
    setFormData,
    onSubmit,
    status,
    showNotification,
    onNotificationClose
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const getAllSurveys = useGetAllSurveys();
  const getSMSTemplates = useGetSMSTemplates();
  const [initialized, setInitialized] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  // Data polled from server
  const surveys: SurveyData[] = getAllSurveys.data;
  const templates = getSMSTemplates.data?.reduce(
    (acc: Record<string, any>, curr: Record<string, any>) => ({
      ...acc,
      [curr.title]: curr.message
    }),
    {}
  );

  useEffect(() => {
    if (!initialized) {
      getAllSurveys.request({});
      getSMSTemplates.request({});
      setInitialized(true);
    }
  }, [getAllSurveys, getSMSTemplates, initialized]);

  return (
    <React.Fragment>
      {props.button ? (
        <props.button onClick={() => setOpen(true)} />
      ) : (
        <Button
          className={classes.button}
          color="primary"
          onClick={() => setOpen(true)}
        >
          Lähetä kysely tai viesti
        </Button>
      )}

      <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className={classes.title}>
          Lähetä kysely tai viesti
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl className={classes.formControl}>
            <InputLabel>Viestipohjat</InputLabel>
            <Select
              input={<Input />}
              value={selectedTemplate}
              onChange={event => {
                setSelectedTemplate(event.target.value as string);
                setFormData({
                  ...formData,
                  // Clear surveyUrl if message template not selected
                  ...(event.target.value === "" ? { surveyUrl: null } : {}),
                  message: templates[event.target.value as string]
                });
              }}
            >
              <MenuItem aria-label="Ei kyselyä" value="">
                Ei viestipohjaa
              </MenuItem>
              {templates &&
                Object.keys(templates).map((title: string, idx: number) => (
                  <MenuItem key={idx} value={title}>
                    {title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            className={classes.textField}
            value={formData.message || ""}
            onChange={event =>
              setFormData({
                ...formData,
                message: event.target.value as string
              })
            }
            label="Viesti"
            placeholder="Kirjoita tähän viesti potilaalle"
            rows={4}
            variant="outlined"
            disabled={selectedTemplate !== ""}
            autoFocus
            multiline
            fullWidth
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Kysely</InputLabel>
            <Select
              input={<Input />}
              value={selectedTemplate === "" ? "" : formData.surveyUrl || ""}
              disabled={selectedTemplate === ""}
              onChange={event =>
                setFormData({
                  ...formData,
                  surveyUrl: event.target.value as string
                })
              }
            >
              <MenuItem aria-label="Ei kyselyä" value="">
                Ei kyselyä
              </MenuItem>
              <MenuItem
                aria-label="Viimeisin lähetetty kysely"
                value="__LAST_SURVEY__"
              >
                Viimeisin lähetetty kysely
              </MenuItem>
              {surveys &&
                surveys
                  .filter(survey =>
                    survey.name.startsWith(
                      process.env.REACT_APP_SMS_SURVEY_PREFIX || ""
                    )
                  )
                  .map((survey: Record<string, any>) => (
                    <MenuItem key={survey.id} value={survey.url}>
                      {survey.name}
                    </MenuItem>
                  ))}
            </Select>
            <FormControlLabel
              className={classes.checkbox}
              label="Testaus, tekstiviestiä ei lähetetä"
              checked={formData.dryrun}
              onChange={() =>
                setFormData({ ...formData, dryrun: !formData.dryrun })
              }
              control={<Checkbox name="testaus" />}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClose}>
            Peruuta
          </Button>
          <Button
            className={classes.sendButton}
            variant="contained"
            color="primary"
            disabled={formData.message == null || formData.message.length === 0}
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            Lähetä
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showNotification}
        onClose={onNotificationClose}
        autoHideDuration={4000}
      >
        <MuiAlert
          elevation={1}
          variant="filled"
          onClose={onNotificationClose}
          severity={status === "success" ? "success" : "error"}
        >
          {status === "success"
            ? "Viesti lähetetty!"
            : "Pahoittelut, joitain meni pieleen -- viestiä ei pystytty lähettämään."}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}

function PatientModal(props: any) {
  const patientState = usePatientState();
  const seDispatch = useSEDispatch();
  const [formData, setFormData] = useState({
    id: patientState.patient?.id || -1,
    message: "",
    surveyUrl: undefined,
    dryrun: false
  } as NewSMS);
  const postSMS = usePostSMS(patientState.patient?.id || -1);
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>(
    "unsent"
  );
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (postSMS.data) {
      seDispatch({
        type: SEActionTypes.AddTexts,
        value: [postSMS.data] as SMS[]
      });
      setSubmissionStatus("success");
      setShowNotification(true);
    }
  }, [postSMS.data, seDispatch]);

  useEffect(() => {
    if (postSMS.error) {
      setSubmissionStatus("error");
      setShowNotification(true);
    }
  }, [postSMS.error]);

  return (
    <Modal
      formData={formData}
      setFormData={setFormData}
      onSubmit={() => postSMS.request(formData)}
      status={submissionStatus}
      showNotification={showNotification}
      onNotificationClose={() => setShowNotification(false)}
    />
  );
}

function MassModal({
  patientIds,
  ButtonComponent
}: {
  patientIds: number[];
  ButtonComponent: any;
}) {
  const [formData, setFormData] = useState<NewSMSMultiple>({
    patientIds: [],
    message: "",
    surveyUrl: undefined,
    dryrun: false
  });
  const postMultipleSMS = usePostMultipleSMS();
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>(
    "unsent"
  );
  const [showNotification, setShowNotification] = useState(false);

  // Ensure updated checkbox selections are reflected in form data
  useEffect(() => {
    if (!equals(formData.patientIds, patientIds)) {
      setFormData({ ...formData, patientIds });
    }
  }, [formData, patientIds]);

  useEffect(() => {
    if (postMultipleSMS.data) {
      setSubmissionStatus("success");
      setShowNotification(true);
    } else if (postMultipleSMS.error) {
      setSubmissionStatus("error");
      setShowNotification(true);
    }
  }, [postMultipleSMS.data, postMultipleSMS.error]);

  return (
    <Modal
      button={ButtonComponent}
      formData={formData}
      setFormData={setFormData}
      onSubmit={() => postMultipleSMS.request(formData)}
      status={submissionStatus}
      showNotification={showNotification}
      onNotificationClose={() => setShowNotification(false)}
    />
  );
}

export default function SMSModal(props: any) {
  return props.mass ? <MassModal {...props} /> : <PatientModal {...props} />;
}
