import { makeRequestHook } from "../utils/requestHook";
import { get } from "../utils/httpUtils";

const endpoint = "/v1/surveys";

export type SurveysData = string[];

function getAllSurveys(data: any): Promise<SurveysData> {
  return get<any>(endpoint, undefined);
}

export function useGetAllSurveys() {
  return makeRequestHook<any, {}>(getAllSurveys)(undefined);
}
