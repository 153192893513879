import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useCreateOne, TicketType, TicketStatus } from "../api/ticket";

const PUBLIC_URL = process.env.PUBLIC_URL;

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "70px",
      paddingBottom: "70px",
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    },
    infotext: {
      maxWidth: "700px",
      width: "100%",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    title: {
      "& h2": {
        color: palette.text.primary,
        fontWeight: 400
      },
      paddingBottom: 0
    },
    content: {
      padding: "0 24px 12px 24px"
    },
    formControl: {
      minWidth: 200
    },
    textField: {
      marginBottom: "1rem",
      marginTop: "1.25rem"
    },
    checkbox: {
      marginTop: "1rem"
    },
    actions: {
      padding: "16px 24px",
      "& button": {
        marginRight: "0.5rem",
        "&:last-of-type": {
          marginRight: 0
        }
      }
    }
  })
);

export default function InfoPage(props: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { request } = useCreateOne();

  const closeModal = () => setOpen(false);

  return (
    <div className={classes.container}>
      <Typography variant="h5" component="h2">
        Kiitos osallistumisestasi!
      </Typography>
      <div className={classes.infotext}>
        <p>
          Tupakoinnin lopettaminen ei ole helppoa. Tunnetusti se voi vaatia
          useampiakin yrityksiä, mutta silti lopettaminen tuo merkittäviä
          hyötyjä elämään ja sitä kannattaa aktiivisesti tavoitella.
        </p>
        <p>
          Käsittelemme henkilötietojasi ainoastaan yhteydenottopyyntöjen ja
          esimerkiksi lääkereseptin kirjoittamisen yhteydessä. Palvelun
          tietoturvallisuus on meille tärkeää.
        </p>
        <p>
          Tämä malli on nyt kokeilussa ja toivomme tietysti ensisijaisesti
          onnistumisia kotirintamalla, mutta jos me onnistuimme tai
          epäonnistuimme jossain, niin anna palautetta!
        </p>
      </div>
      <Button
        color="primary"
        onClick={() =>
          window.open(`${PUBLIC_URL}/assets/Tietosuojaseloste.pdf`, "_blank")
        }
      >
        Palvelun Tietosuojaseloste
      </Button>
      <br />
      <Button color="primary" onClick={() => setOpen(true)}>
        Anna palautetta
      </Button>
      <Dialog open={open} onClose={closeModal} maxWidth={"sm"} fullWidth>
        <DialogTitle className={classes.title}>Anna palautetta</DialogTitle>
        <DialogContent className={classes.content}>
          <p>
            Palaute lähetetään nimettömänä eikä sitä yhdistetä muihin
            vastauksiisi. Jos haluat olla henkilökohtaisesti yhteydessä, voit
            laittaa viestiä{" "}
            <a href="mailto:info@digilaakari.fi">info@digilaakari.fi</a>
          </p>
          <TextField
            className={classes.textField}
            value={feedback}
            onChange={event => setFeedback(event.target.value as string)}
            label="Viesti"
            placeholder="Kirjoita tähän palautteesi"
            rows={4}
            variant="outlined"
            autoFocus
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={closeModal}>
            Peruuta
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              request({
                type: TicketType.Feedback,
                title: "Palaute info-sivulta",
                text: feedback,
                path: "",
                status: TicketStatus.Open
              });
              closeModal();
            }}
          >
            Lähetä
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
