import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    // TODO: this needs to be reworked, but for now, just override everything
    root: {
      borderRadius: "30px !important",
      border: `1px solid ${palette.primary.main} !important`,
      margin: "5px 10px !important",
      padding: "10px 20px !important"
    }
  })
);

export default function RoundButton(props: any) {
  const classes = useStyles();

  return (
    <Button
      {...props}
      variant={props.selected ? "contained" : "outlined"}
      color="primary"
      disableElevation
      className={
        props.className ? `${props.className} ${classes.root}` : classes.root
      }
    />
  );
}
