import React, { useReducer, useEffect, useRef, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { take, takeWhile } from "ramda";
import FrontView from "./FrontView";
import BotView from "./BotView";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  AnswerOption,
  QuestionData,
  useGetQuestion,
  getNextQuestionPayload,
  getConsultationOptionsPayload,
  getDirectSearchPayload,
  searchToAnswerOption,
  getQueryPayload
} from "../../api/getQuestion";
import { useGetLink } from "./../../api/getLink";
import { useGetAllKeywords } from "../../api/allKeywords";
import Loading from "../../components/Loading";
import { useLocation, useParams, useHistory, Redirect } from "react-router-dom";
import { isSmokeExit } from "../../config";
import paths from "../../paths";

const styles: Record<string, CSSProperties> = {
  root: {
    width: "100%"
  },
  swipeableRoot: {
    height: "100%",
    width: "100%"
  },
  linkViewRoot: {
    marginTop: "4rem"
  }
};

const springConfig = {
  duration: "0.5s",
  easeFunction: "cubic-bezier(0.25, 0.1, 0.25, 1.0)",
  delay: "0.2s"
};

const useStyles = makeStyles(({ palette, mixins }: Theme) =>
  createStyles(styles)
);

enum BotActionTypes {
  ChooseOption,
  AddAnswer,
  AddMessage,
  ResetAnswer,
  SetView,
  ResetQA,
  SetKeywords,
  SetRemoteOptions,
  FinishTransition
}

enum ViewTypes {
  Front = 0,
  Bot = 1
}

type BotState = {
  messages: QuestionData[];
  answers: AnswerOption[];
  view: ViewTypes;
  remoteOptions: QuestionData | null;
  keywords: string[] | null;
  option: Record<string, any> | null;
  transitioning: boolean;
};

type BotAction = {
  type: BotActionTypes;
  answer?: AnswerOption;
  message?: QuestionData;
  view?: ViewTypes;
  options?: QuestionData;
  option?: { type: "remote" | "search" | "query"; option: any };
  keywords?: string[];
};

function trimStateByAnswer(
  messages: QuestionData[],
  answers: AnswerOption[],
  answer: AnswerOption
) {
  const trimmedAnswers = takeWhile(
    val => val.id !== answer.id || val.target !== answer.target,
    answers
  );

  // Remove answer, but keep the original message itself
  return {
    answers: trimmedAnswers,
    messages: take(trimmedAnswers.length + 1, messages)
  };
}

function botReducer(state: BotState, action: BotAction): BotState {
  switch (action.type) {
    case BotActionTypes.ChooseOption:
      // action.option is of kind { type: "search" | "remote" | "query", option: <option data> }
      return action.option
        ? {
            ...state,
            option: action.option,
            view: ViewTypes.Bot,
            transitioning: true
          }
        : state;
    case BotActionTypes.AddAnswer:
      return action.answer
        ? { ...state, answers: [...state.answers, action.answer] }
        : state;
    case BotActionTypes.AddMessage:
      return action.message
        ? { ...state, messages: [...state.messages, action.message] }
        : state;
    case BotActionTypes.ResetAnswer:
      return action.answer
        ? {
            ...state,
            ...trimStateByAnswer(state.messages, state.answers, action.answer)
          }
        : state;
    case BotActionTypes.SetView:
      return action.view !== undefined
        ? { ...state, view: action.view, transitioning: true }
        : state;
    case BotActionTypes.ResetQA:
      // also reset selected option
      return {
        ...state,
        messages: [],
        answers: [],
        option: { type: "none", option: null }
      };
    case BotActionTypes.SetRemoteOptions:
      return action.options
        ? {
            ...state,
            remoteOptions: action.options
          }
        : state;
    case BotActionTypes.SetKeywords:
      return action.keywords ? { ...state, keywords: action.keywords } : state;
    case BotActionTypes.FinishTransition:
      return { ...state, transitioning: false };
    default:
      return state;
  }
}

const initialBotState: BotState = {
  messages: [],
  answers: [],
  view: ViewTypes.Front,
  remoteOptions: null,
  keywords: null,
  option: null,
  transitioning: false
};

// Set remote consultation option on FrontView
const setRemoteOption = (
  state: BotState,
  dispatch: React.Dispatch<BotAction>,
  getQuestion: any
) => (optionsQuestion: QuestionData, selection: AnswerOption) => {
  dispatch({
    type: BotActionTypes.ChooseOption,
    option: { type: "remote", option: selection }
  });
  // fetch first bot message for option
  getQuestion.request(getNextQuestionPayload(optionsQuestion, selection));
};

// Set direct search term from search field on FrontView
const setDirectSearch = (
  state: BotState,
  dispatch: React.Dispatch<BotAction>,
  getQuestion: any
) => (searchTerm: string) => {
  dispatch({
    type: BotActionTypes.ChooseOption,
    option: { type: "search", option: searchToAnswerOption(searchTerm) }
  });
  // fetch first bot message for search
  getQuestion.request(getDirectSearchPayload(searchTerm));
};

const setQuerySearch = (
  state: BotState,
  dispatch: React.Dispatch<BotAction>,
  getQuestion: any
) => (query: { q: string | null; v: string | null }) => {
  dispatch({
    type: BotActionTypes.ChooseOption,
    option: { type: "query", option: query }
  });
  // fetch first bot message for query
  getQuestion.request(getQueryPayload(query));
};

const setLinkSearch = (
  state: BotState,
  dispatch: React.Dispatch<BotAction>,
  getQuestion: any
) => (uuid: string) => {
  dispatch({
    type: BotActionTypes.ChooseOption,
    option: { type: "search", option: { text: "Linkki" } }
  });
  // fetch first bot message for query
  getQuestion.request();
};

function getQParams(queryString: string) {
  const paramsObj = new URLSearchParams(queryString);
  return { q: paramsObj.get("q"), v: paramsObj.get("v") };
}

function initialLoading(state: BotState, isQuery: boolean) {
  const hasQueried = state.messages[0];
  const hasOptions =
    !!state.keywords && !!state.remoteOptions?.answerOptions?.length;
  return isQuery ? !hasQueried : !hasOptions;
}

function getNameOfOption(state: BotState): string {
  if (!state.option) return "";
  switch (state.option.type) {
    case "remote":
    case "search":
      return state.option.option.text;
    case "query":
      return state.option.option.q;
    default:
      return "";
  }
}

const isLinkPage = (pathname: string) => !!pathname.match("/link");

export default function NewPatientPage(props: any) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [botState, botDispatch] = useReducer(botReducer, initialBotState);
  const getQuestion = useGetQuestion();
  const getRemoteOptions = useGetQuestion();
  const getAllKeywords = useGetAllKeywords();
  const refMessagesDiv = useRef<any>(null);
  const query = getQParams(location.search);
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { uuid } = useParams<{uuid?: string;}>();
  const getLink = useGetLink(uuid);

  // Initialize
  useEffect(() => {
    if (!initialized) {
      // If called directly with "/kysely", then redirect to "/uusi"
      if (location.pathname === "/kysely") {
        history.push("/uusi");
      }
      // For query params (e.g., "?q=triagekorona&v=2")
      if (query.q) {
        setQuerySearch(botState, botDispatch, getQuestion)(query);
      }
      // For links (e.g./link/17dcee16-44fa-4760-83fd-e916f0350361)
      if (uuid) {
        setLinkSearch(botState, botDispatch, getLink)(uuid);
        botDispatch({ type: BotActionTypes.FinishTransition });
      } else {
        // Populate state, FrontView
        getRemoteOptions.request(getConsultationOptionsPayload());
        getAllKeywords.request({});
      }
      // Don't re-run this useEffect
      setInitialized(true);
    }
  }, [
    botState,
    getAllKeywords,
    getQuestion,
    getRemoteOptions,
    initialized,
    query,
    getLink,
    uuid,
    location.pathname,
    history
  ]);

  useEffect(() => {
    if (isLoading && !initialLoading(botState, !!query.q || !!uuid)) {
      setLoading(false);
    }
  }, [botState, isLoading, query.q, uuid]);

  // Handle consultation options, keywords
  useEffect(() => {
    if (!botState.remoteOptions && getRemoteOptions.data) {
      botDispatch({
        type: BotActionTypes.SetRemoteOptions,
        options: getRemoteOptions.data
      });
    }
    if (!botState.keywords && getAllKeywords.data) {
      botDispatch({
        type: BotActionTypes.SetKeywords,
        keywords: getAllKeywords.data
      });
    }
  }, [
    botState.remoteOptions,
    botState.keywords,
    getAllKeywords.data,
    getRemoteOptions.data
  ]);

  // Every time new bot question data flows through...
  useEffect(() => {
    // Ignore invalid responses
    if (getQuestion.data && getQuestion.data.answerId !== -1) {
      botDispatch({
        type: BotActionTypes.AddMessage,
        message: getQuestion.data
      });
    }
  }, [getQuestion.data]);

  // Process first answer from getLink api endpoint
  useEffect(() => {
    // Ignore invalid responses
    if (getLink.data && getLink.data.answerId !== -1) {
      botDispatch({
        type: BotActionTypes.AddMessage,
        message: getLink.data
      });
    }
  }, [getLink.data]);

  // Check if should scroll into view
  useEffect(() => {
    const div = refMessagesDiv.current;
    if (div && botState.view === ViewTypes.Bot && !botState.transitioning) {
      div.scrollIntoView({
        block: "end",
        behavior: "smooth",
        inline: "nearest"
      });
    }
  }, [botState.messages, botState.view, botState.transitioning]);

  // Guard against back button presses
  if (!isLinkPage(location.pathname) && history.action === "POP") {
    return <Redirect to="/" />;
  }

  return isLoading ? (
    <div className={`${classes.root} ${!!uuid ? classes.linkViewRoot : ""}`}>
      <Loading />
    </div>
  ) : (
    <div className={`${classes.root} ${!!uuid ? classes.linkViewRoot : ""}`}>
      <SwipeableViews
        index={botState.view}
        className={classes.swipeableRoot}
        springConfig={springConfig}
        onTransitionEnd={() => {
          botDispatch({ type: BotActionTypes.FinishTransition });
          if (botState.view === ViewTypes.Front)
            botDispatch({ type: BotActionTypes.ResetQA });
          if (!isLinkPage(location.pathname)) {
            const frontViewUrl = isSmokeExit
              ? paths.patients
              : paths.newPatient;
            const botViewUrl = paths.botView;
            history.push(
              botState.view === ViewTypes.Front ? frontViewUrl : botViewUrl
            );
          }
        }}
      >
        <FrontView
          keywords={botState.keywords || ([] as string[])}
          remoteOptions={
            botState.remoteOptions?.answerOptions || ([] as AnswerOption[])
          }
          selectedRemoteOption={
            (botState.option?.type === "remote" && botState.option.option) ||
            undefined
          }
          setRemoteOption={(selection: AnswerOption) => {
            return botState.remoteOptions
              ? setRemoteOption(
                  botState,
                  botDispatch,
                  getQuestion
                )(botState.remoteOptions, selection)
              : undefined;
          }}
          setDirectSearch={setDirectSearch(botState, botDispatch, getQuestion)}
        />
        <BotView
          isLinkView={!!uuid}
          title={getNameOfOption(botState)}
          toggleView={() => {
            botDispatch({
              type: BotActionTypes.SetView,
              view: ViewTypes.Front
            });
          }}
          messages={botState.messages}
          answers={botState.answers}
          setAnswer={(answer: AnswerOption) => {
            const answered = botState.answers.find(
              item => item.id === answer.id && item.target === answer.target
            );
            if (answered) {
              botDispatch({ type: BotActionTypes.ResetAnswer, answer });
            } else {
              // Update answer ...
              botDispatch({ type: BotActionTypes.AddAnswer, answer });
              // ... and do API request for next message
              getQuestion.request(
                getNextQuestionPayload(botState.messages.slice(-1)[0], answer)
              );
            }
          }}
          refMessagesDiv={refMessagesDiv}
        />
      </SwipeableViews>
    </div>
  );
}
