import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useGetPatients } from "../../api/patients";
import Loading from "../../components/Loading";
import { isSmokeExit } from "../../config";
import PatientList from "./PatientList";
import SEPatientList from "./SEPatientList";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    heading: {
      marginBottom: "1rem"
    },
    headingSE: {
      marginBottom: "1rem",
      marginTop: "4rem"
    },
    patientList: {
      maxWidth: isSmokeExit ? "1400px" : "1000px",
      width: isSmokeExit ? "90%" : "100%"
    }
  })
);

type PatientsPageProps = {
  setReloadPatients: React.Dispatch<React.SetStateAction<boolean>>;
  reloadPatients: boolean;
};

export default function PatientsPage(props: PatientsPageProps) {
  const classes = useStyles();
  const getPatients = useGetPatients();
  const reqGetPatients = getPatients.request;
  const getPatientsData = getPatients.data;
  const [initialized, setInitialized] = useState(false);
  const { reloadPatients, setReloadPatients } = props;
  const filteredPatients = (listName: string) => {
    const filteredPatients = getPatientsData.filter((patient: any) => {
      if (!patient.data?.listName && listName === "open") {
        return patient;
      }
      if (patient.data?.listName === listName) {
        return patient;
      }
      return false;
    });
    return filteredPatients;
  };
  const openPatients = getPatientsData && filteredPatients("open");
  const closedPatients = getPatientsData && filteredPatients("closed");
  const callMonitoringPatients =
    getPatientsData && filteredPatients("callMonitoring");
  const botMonitoringPatients =
    getPatientsData && filteredPatients("botMonitoring");

  useEffect(() => {
    if (!initialized) {
      reqGetPatients({});
      setInitialized(true);
    }
  }, [initialized, reqGetPatients]);

  useEffect(() => {
    if (reloadPatients) {
      reqGetPatients({});
      setReloadPatients(false);
    }
  }, [reloadPatients, reqGetPatients, setReloadPatients]);

  return getPatientsData === null ? (
    <Loading />
  ) : (
    <React.Fragment>
      {isSmokeExit ? (
        <>
          <Typography className={classes.heading} variant="h5" component="h2">
            Soitettavat potilaat
          </Typography>
          <SEPatientList
            className={classes.patientList}
            rows={[...openPatients]}
          />
          <Typography className={classes.headingSE} variant="h5" component="h2">
            Seurannassa olevat potilaat
          </Typography>
          <SEPatientList
            className={classes.patientList}
            rows={[...callMonitoringPatients]}
          />
          <Typography className={classes.headingSE} variant="h5" component="h2">
            Kutsutut potilaat
          </Typography>
          <SEPatientList
            className={classes.patientList}
            rows={[...botMonitoringPatients]}
          />
          <Typography className={classes.headingSE} variant="h5" component="h2">
            Suljetut potilaat
          </Typography>
          <SEPatientList
            className={classes.patientList}
            rows={[...closedPatients]}
          />
        </>
      ) : (
        <>
          <Typography className={classes.heading} variant="h5" component="h2">
            Aktiiviset potilaat
          </Typography>
          <PatientList className={classes.patientList} rows={getPatientsData} />
        </>
      )}
    </React.Fragment>
  );
}
