export enum ClinicianStatus {
  EiVoidaHoitaa = -1,
  Kesken = 0,
  Hoidettu = 1,
  Konsultoitu = 2
}

export const clinicianStatuses = [
  {
    label: "Ei voida hoitaa",
    value: ClinicianStatus.EiVoidaHoitaa,
    attr: "unable-to-treat",
    billingInfo: "Ei laskuteta",
    userTypes: ["DOCTOR", "NURSE"]
  },
  {
    label: "Kesken",
    value: ClinicianStatus.Kesken,
    attr: "unfinished",
    userTypes: ["DOCTOR", "NURSE"]
  },
  {
    label: "Hoidettu",
    value: ClinicianStatus.Hoidettu,
    attr: "treated-remotely",
    billingInfo: "Laskutetaan",
    userTypes: ["DOCTOR", "NURSE"]
  },
  {
    label: "Konsultoitu",
    value: ClinicianStatus.Konsultoitu,
    attr: "consulted",
    billingInfo: "Ei laskuteta",
    userTypes: ["DOCTOR"]
  }
];
