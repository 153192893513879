import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { clinicianStatuses } from "../../api/consts";
import { useUserState } from "../../api/UserProvider";
import { usePatientState } from "../../api/PatientProvider";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    root: {
      marginBottom: "2rem",
      [breakpoints.down("sm")]: {
        maxWidth: "500px",
        margin: "auto"
      }
    },
    buttonGroup: {
      marginBottom: "1rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    alert: {
      background: "none",
      padding: 0
    }
  })
);

// We don't want larger buttons to be the same size as other buttons in the group
// This constrains smaller buttons to smaller flex-grow values
const flexValue = (statuses: any[], idx: number) => {
  const widths = statuses.map(status => status.label.length);
  const width = widths[idx];
  return Math.round((2 * width) / Math.max(...widths));
};

function findStatus(statuses: any, clinicianStatus: undefined | number) {
  return clinicianStatus !== undefined
    ? clinicianStatuses.find(status => status.value === clinicianStatus)
    : null;
}

export default function StatusUpdate({ updatePatient, ...rest }: any) {
  const classes = useStyles();
  const userState = useUserState();
  const patientState = usePatientState();
  const statuses = clinicianStatuses.filter(status =>
    status.userTypes.includes(userState?.user?.usertype || "")
  );
  const currStatus = findStatus(
    statuses,
    patientState?.patient?.clinicianStatus
  );

  return (
    <div className={classes.root}>
      <ButtonGroup size="small" color="primary" className={classes.buttonGroup}>
        {statuses.map((status, idx) => (
          <Button
            style={{
              padding: "0.5rem 1rem",
              flex: `${flexValue(statuses, idx)}`
            }}
            key={status.value + 1}
            variant={
              status.value === currStatus?.value ? "contained" : "outlined"
            }
            onClick={() => updatePatient({ clinicianStatus: status.value })}
            disableElevation
          >
            {status.label}
          </Button>
        ))}
      </ButtonGroup>
      {currStatus?.billingInfo && userState.user?.usertype === "DOCTOR" && (
        <Alert severity="info" className={classes.alert}>
          {currStatus.billingInfo}
        </Alert>
      )}
    </div>
  );
}
