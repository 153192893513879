import { Dispatch, SetStateAction, useEffect, useState } from "react";

type PaginationData<T> = {
  page: T[];
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  rowsCount: number;
  setRowsCount: Dispatch<SetStateAction<number>>;
  setFullCollection: Dispatch<SetStateAction<T[]>>;
};

export default function useCollectionPagination<T>(
  initPageNumber: number = 0,
  initRowsCount: number = 10
): PaginationData<T> {
  const [pageNumber, setPageNumber] = useState<number>(initPageNumber);

  const [rowsCount, setRowsCount] = useState<number>(initRowsCount);

  const [fullCollection, setFullCollection] = useState<T[]>([]);

  const [page, setPage] = useState<T[]>([]);

  useEffect(() => {
    const page = fullCollection.slice(
      pageNumber * rowsCount,
      (pageNumber + 1) * rowsCount
    );

    if (page.length === 0 && pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }

    setPage(page);
  }, [fullCollection, pageNumber, rowsCount]);

  return {
    page,
    pageNumber,
    setPageNumber,
    rowsCount,
    setRowsCount,
    setFullCollection
  };
}
