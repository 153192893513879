import { createMuiTheme } from "@material-ui/core/styles";
import { fiFI } from "@material-ui/core/locale";

export default createMuiTheme(
  {
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#3476AE"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: "#42bb48",
        contrastText: "#fff"
      },
      default: {
        main: "#555"
      },
      text: {
        primary: "#0D294A"
      }
    },
    typography: {
      h1: {
        fontSize: 40,
        fontWeight: 800
      },
      h2: {
        fontSize: 26,
        fontWeight: 800
      },
      button: {
        textTransform: "none",
        fontSize: 16
      }
    },
    overrides: {
      MuiFab: {
        extended: {
          height: 52,
          borderRadius: 26
        }
      },
      MuiButton: {
        contained: {
          backgroundColor: "white",
          border: "1px solid #3476AE",
          padding: "6px 12px"
        },
        text: {
          color: "#555"
        }
      },
      MuiSwitch: {
        switchBase: {
          // todo this is fix of skewed switch
          padding: "9px"
        }
      },
      // todo: this is a quick background fix, need to remove this later
      MuiSnackbarContent: {
        root: {
          color: "#FFF !important",
          backgroundColor: "rgb(49, 49, 49) !important"
        }
      }
    }
  },
  fiFI
);
