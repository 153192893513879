import React, { useState } from "react";
import {
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Theme
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useLogin } from "../api/Authorization/Authentication";
import Button from "@material-ui/core/Button/Button";
import LogoWithText from "../components/LogoWithText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(10)
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    loginContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "335px"
    },
    logoWithText: {
      marginBottom: "2rem"
    },
    loginForm: {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    loginTitle: {
      marginBottom: "90px"
    },
    formControl: {
      width: "100%"
    },
    "@media (max-width: 700px)": {
      root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      }
    },
    inputSpacer: {
      marginBottom: theme.spacing(2)
    }
  })
);

export default function LoginPage(props: any) {
  if (process.env.REACT_APP_AUTH0_LOGIN === "true")
    return (
      <Auth0Login redirectTo={((props.location || {}).state || {}).referrer} />
    );
  else return <LocalLogin />;
}

function Auth0Login(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form className={classes.form} action="/login" method="POST">
        <input
          type="hidden"
          name="redirectTo"
          value={props.redirectTo || window.location.href || "/"}
        />
        <div className={classes.loginContainer}>
          <LogoWithText fontSize="2.3em" className={classes.logoWithText} />
          <div className={classes.loginForm}>
            <Button
              type={"submit"}
              variant="contained"
              color="primary"
              aria-label="login"
            >
              Kirjaudu
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

function LocalLogin() {
  const classes = useStyles();

  const { loading, error, request } = useLogin();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={classes.root}>
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault();
          request({ username, password });
        }}
      >
        <div className={classes.loginContainer}>
          <LogoWithText fontSize="2.3em" className={classes.logoWithText} />
          <div className={classes.loginForm}>
            <div className={classes.inputSpacer}>
              <FormControl
                error={error && !loading}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="username">Käyttäjänimi</InputLabel>
                <OutlinedInput
                  autoFocus
                  id="username"
                  labelWidth={100}
                  value={username}
                  onChange={event => setUsername(event.target.value)}
                />
                <FormHelperText>
                  {error && !loading && "Credentials are invalid"}
                </FormHelperText>
              </FormControl>
            </div>

            <div className={classes.inputSpacer}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="password">Salasana</InputLabel>
                <OutlinedInput
                  id="password"
                  type="password"
                  labelWidth={100}
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
            </div>

            <Button
              type={"submit"}
              disabled={loading}
              variant="contained"
              color="primary"
              aria-label="login"
            >
              Kirjaudu
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
