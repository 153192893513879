import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Chip,
  Avatar,
  Paper
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { fmtTimeDist, lastPatientUpdate } from "../../utils/date";
import { PatientsData } from "../../api/patients";
import { useHistory } from "react-router-dom";
import paths from "../../paths";
import StatusChip from "../../components/StatusChip";

type Dict = Record<string, any>;

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    table: {
      "& .MuiTableCell-body, & .MuiTableCell-root": {
        textAlign: "center"
      },
      // Clickable buttons
      "& .MuiIconButton-root": {
        padding: "5px"
      },
      // Soften coloring for notification icons
      "& svg.MuiSvgIcon-root": {
        opacity: 0.85
      }
    },
    tableRow: {
      textDecoration: "none"
    },
    chip: {
      marginLeft: "0.5rem",
      cursor: "pointer",
      [breakpoints.down("xs")]: {
        maxWidth: "max-content"
      }
    },
    chipsCell: {
      [breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        "& > div:last-of-type": {
          marginTop: "0.25rem"
        }
      }
    },
    desktopCell: {
      [breakpoints.down("xs")]: {
        display: "none"
      }
    }
  })
);

function makeUpdateCell(update: [string, string]) {
  return (
    <Chip
      style={{
        marginLeft: "0.5rem",
        cursor: "pointer",
        background: "transparent"
      }}
      size="small"
      avatar={<Avatar>{update[0]}</Avatar>}
      label={update[1]}
    />
  );
}

function makeLastUpdateCell(
  clinicianUpdate: string | null,
  nurseUpdate: string | null
) {
  const lastUpdate = lastPatientUpdate(clinicianUpdate, nurseUpdate);
  if (!lastUpdate) return undefined;
  return makeUpdateCell(lastUpdate);
}

export default function PatientList(props: any) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows] = useState(props.rows);
  const history = useHistory();

  return (
    <TableContainer component={Paper} {...props}>
      <Table
        className={classes.table}
        size="small"
        aria-label="potilaslista"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell>Potilas ID</TableCell>
            <TableCell>Lääkäri</TableCell>
            <TableCell>Status</TableCell>
            <TableCell className={classes.desktopCell}>Luotu</TableCell>
            <TableCell className={classes.desktopCell}>
              Viimeksi päivitetty
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: PatientsData, idx: number) => (
              <TableRow
                className={classes.tableRow}
                hover
                key={idx}
                onClick={() => history.push(`${paths.patientCard}/${row.id}`)}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.clinician}</TableCell>
                <TableCell className={classes.chipsCell}>
                  {row.nurseStatus === row.clinicianStatus ? (
                    <StatusChip
                      className={classes.chip}
                      status={row.nurseStatus}
                    />
                  ) : (
                    <React.Fragment>
                      <StatusChip
                        className={classes.chip}
                        type="SH"
                        status={row.nurseStatus}
                      />
                      <StatusChip
                        className={classes.chip}
                        type="MD"
                        status={row.clinicianStatus}
                      />
                    </React.Fragment>
                  )}
                </TableCell>
                <TableCell className={classes.desktopCell}>
                  {fmtTimeDist(new Date(row.createdAt))}
                </TableCell>
                <TableCell className={classes.desktopCell}>
                  {makeLastUpdateCell(
                    row.clinicianLastUpdate,
                    row.nurseLastUpdate
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={visibleRows.length}
              onChangePage={(_event: any, page: number) => setPage(page)}
              onChangeRowsPerPage={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
