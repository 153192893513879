import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SearchBox from "../../components/SearchBox";
import { AnswerOption } from "../../api/getQuestion";
import { KeywordsData } from "../../api/allKeywords";
import { isSmokeExit } from "../../config";
import PatientsPage from "../PatientsPage";
import Dropdown from "../../components/Dropdown";
import AddPatient from "./AddPatient";

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    root: {
      overflow: "hidden"
    },
    sectionOptions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      [breakpoints.down("xs")]: {
        maxWidth: "350px",
        margin: "auto"
      }
    },
    sectionActivePatients: {
      marginTop: "2rem"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "600px",
      padding: "0px"
    },
    sectionTriage: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      '& div[role="combobox"]': {
        marginTop: "20px",
        width: "300px",
        "& input": {
          paddingTop: "8px !important",
          paddingBottom: "8px !important"
        }
      }
    },
    dropdown: {
      marginLeft: "1rem",
    }
  })
);

export default function FrontView({
  remoteOptions,
  selectedRemoteOption,
  setRemoteOption,
  setDirectSearch,
  keywords
}: {
  remoteOptions: AnswerOption[];
  selectedRemoteOption: AnswerOption | undefined;
  setRemoteOption: (option: AnswerOption) => void;
  setDirectSearch: (searchTerm: string) => void;
  keywords: KeywordsData;
}) {
  const classes = useStyles();
  const [reloadPatients, setReloadPatients] = useState(false)
  return (
    <div className={classes.root}>
      <section className={classes.sectionOptions}>
        <div className={classes.buttonContainer}>
          <AddPatient setReloadPatients={setReloadPatients}/>
          {remoteOptions && (
            <Dropdown
              className={classes.dropdown}
              label={"Muut toimenpiteet"}
              onMenuItemClick={(idx: number) => {
                setRemoteOption(remoteOptions[idx]);
              }}
              menuItems={remoteOptions}
              getMenuItemProps={(option: AnswerOption) => ({
                value: option.id,
                label: option.text
              })}
            />
          )}
        </div>
      </section>
      {!isSmokeExit && (
        <section className={classes.sectionTriage}>
          <SearchBox options={keywords} onSelect={setDirectSearch} />
        </section>
      )}
      {isSmokeExit && (
        <section
          className={`${classes.sectionOptions} ${classes.sectionActivePatients}`}
        >
          <PatientsPage setReloadPatients={setReloadPatients} reloadPatients={reloadPatients}/>
        </section>
      )}
    </div>
  );
}
