import React, { ReactNode, ReactElement } from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Card
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { SMS } from "../../api/sms";
import { useSEState } from "./SEProvider";
import { usePatientState } from "../../api/PatientProvider";
import AnamnesisPanel from "./AnamnesisPanel";
import { fmtStdDateStr } from "../../utils/date";
import SMSModal from "./SMSModal";
import { isSmokeExit } from "../../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: "1rem"
    },
    list: {
      "& > li": {
        paddingLeft: 0
      }
    },
    alert: {
      background: "transparent",
      padding: 0,
      marginTop: "1rem"
    },
    itemText: {
      whiteSpace: "pre-wrap",
      wordWrap: "break-word"
    },
    divider: {
      //backgroundColor: theme.palette.primary.main,
      //height: "0.1rem",
      margin: "1.5rem -16px 0.5rem -16px"
    }
  })
);

export default function SMSHistory() {
  const classes = useStyles();
  const seState = useSEState();

  return (
    <Box>
      <Card className={classes.card}>
        <Typography variant="h6" component="h2">
          Lähetetyt tekstiviestit
        </Typography>
        {isSmokeExit && <SMSModal />}
        {seState.texts.length > 0 ? (
          <List className={classes.list}>
            <SMSMessageItems texts={seState.texts} />
          </List>
        ) : (
          <MuiAlert
            className={classes.alert}
            elevation={0}
            variant="standard"
            severity="info"
          >
            Ei tekstiviestejä
          </MuiAlert>
        )}
      </Card>
    </Box>
  );
}

function SMSMessageItems({ texts }: { texts: SMS[] }): ReactElement {
  const classes = useStyles();
  const patientState = usePatientState();
  const responses = patientState.patient?.responses || [];
  const patientId = patientState.patient?.id ?? 1;

  const nodes: ReactNode[] = [];

  texts.forEach((sms: SMS, idx: number) => {
    // Add sms text
    nodes.push(
      <ListItem key={sms.id}>
        <ListItemText
          className={classes.itemText}
          primary={sms.message}
          secondary={
            fmtStdDateStr(sms.createdAt) +
            (sms.surveyName ? ` (linkin kysely: ${sms.surveyName})` : "")
          }
        />
      </ListItem>
    );

    // Add answer dropdowns for those answers which were given using the link
    // provided in the sms
    if (sms.link != null) {
      nodes.push(
        <AnamnesisPanel
          key={sms.id + "-anamnesis"}
          patientId={patientId}
          responses={responses.filter(r => r.linkId === sms.link)}
        />
      );
    }

    // Add divider after sms messages unless the last message
    if (idx < texts.length - 1)
      nodes.push(<hr key={sms.id + "-divider"} className={classes.divider} />);
  });

  return <>{nodes}</>;
}
