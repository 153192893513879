import React from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { usePatientState } from "../../api/PatientProvider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AnamnesisTable from "./AnamnesisTable";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
    },
    heading: {
      marginBottom: "1rem"
    },
    card: {
      padding: "1rem"
    }
  })
);
export default function LatestSurveyResults() {
  const classes = useStyles();
  const patientState = usePatientState();
  const responses = patientState.patient?.responses || [];
  const patientId = patientState.patient?.id;

  if (patientId === undefined) {
    console.error("Could not render latest survey results: invalid patient id");
    return <div></div>;
  }
  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h6" component="h3" className={classes.heading}>
          Viimeisimmän kyselyn tulokset
        </Typography>
        <AnamnesisTable
          patientId={patientId}
          qa={responses[0]?.allAnswers || []}
        />
      </Card>
    </Box>
  );
}
