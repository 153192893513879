import { format, formatDistance, parse, max, isEqual, isDate } from "date-fns";
import { fi } from "date-fns/locale";
import { findIndex } from "ramda";

type DateRecord = Record<string, Date>;

// Formats input date as distance from current time (in Finnish)
export const fmtTimeDist = (date: Date) =>
  `${formatDistance(new Date(), date, { locale: fi })} sitten`;

export const parseDateStr = (dateStr: string) => {
  // If date is today, then dateStr is formatted like 'klo 14.28'
  if (dateStr.substring(0, 3) === "klo") {
    return parse(dateStr, "'klo' HH.mm", new Date());
  }
  // Date strings like '21.2.2020 klo 14.28'
  return parse(dateStr, "d.M.y 'klo' HH.mm", new Date());
};

export const fmtStdDateStr = (dateStr: string, withHour = true) => {
  const formatString = withHour ? "do MMMM yyyy, 'klo' p" : "do MMMM yyyy";
  return format(new Date(dateStr), formatString, {
    locale: fi
  });
};

export const fmtDateStrShort = (dateStr: string) => {
  return format(new Date(dateStr), "dd.MM.yyyy");
};

// Returns the latest date and index in an array of date strings, formatted by fmtTimeDist
export function latestDate(dateObj: DateRecord): [string, string] {
  const dates = Object.values(dateObj);
  const latest = max(dates);
  const idxLatest = findIndex((d: Date) => isEqual(d, latest))(dates);
  // [key of latest, latest (formatted)]
  return [Object.keys(dateObj)[idxLatest], fmtTimeDist(latest)];
}

export function lastPatientUpdate(
  clinicianUpdate: Date | string | null,
  nurseUpdate: Date | string | null
): [string, string] | null {
  if (clinicianUpdate && nurseUpdate) {
    const dates: DateRecord = {
      MD: isDate(clinicianUpdate)
        ? (clinicianUpdate as Date)
        : new Date(clinicianUpdate),
      SH: isDate(nurseUpdate) ? (nurseUpdate as Date) : new Date(nurseUpdate)
    };
    return latestDate(dates);
  } else if (clinicianUpdate) {
    return ["MD", fmtTimeDist(new Date(clinicianUpdate))];
  } else if (nurseUpdate) {
    return ["SH", fmtTimeDist(new Date(nurseUpdate))];
  }
  return null;
}
