export default {
  root: "/",
  index: "/index.html",
  login: "/login",
  patients: "/potilaat",
  newPatient: "/uusi",
  survey: "/(uusi|kysely)",
  botView: "/kysely",
  smokeExitSurvey: "/(potilaat|kysely)",
  patientCard: "/potilaskortti",
  patientCardForId: "/potilaskortti/:patientId",
  information: "/info",
  links: "/link/:uuid"
};
