import { makeRequestHook } from "../utils/requestHook";
import { get, put } from "../utils/httpUtils";
import { Ticket } from "./ticket";
import { isSmokeExit } from "../config";

const endpoint = "/v1/patient";

type QA = {
  question: string;
  answer: string;
};

type Date = null | string;

type SurveyStatus = {
  status:
    | "NOT_STARTED"
    | "LINK_SENT"
    | "REMINDER_SENT"
    | "ANSWERED"
    | "VISITED";
  date: Date;
};

export interface Smokexit {
  smoking: {
    status: string;
    dateStopped: Date;
  };
  latestResponse: {
    surveyName: string;
    date: Date;
  };
  startSurveyDate: string;
  startSurveyStatus: SurveyStatus;
  followupSurveyStatus: SurveyStatus;
}

export interface Response {
  surveyName: string;
  surveyTitle: string;
  createdAt: string;
  allAnswers: QA[];
  linkId: string | null;
}

export type PatientData = {
  id: number;
  nurseId: string;
  nurseName?: string;
  nurseStatus: number;
  nurseLastUpdate: null | string;
  clinicianId: string;
  clinicianName: string;
  clinicianStatus: number;
  clinicianLastUpdate: null | string;
  selectedNodes: Record<string, any>;
  createdAt: string;
  notification: boolean;
  allAnswers: QA[];
  responses: Response[];
  tickets: Ticket[];
  smokexit?: Smokexit;
  data: Record<string, any>;
};

const fullEndpoint = (patientId: number, selectedNodes?: string[]) => {
  let address = `${endpoint}/${patientId}`;
  const searchParams = new URLSearchParams();
  // Add selectedNodes parameter
  if (selectedNodes && selectedNodes.length > 0)
    selectedNodes.forEach(param => searchParams.append("selectedNodes", param));
  // Add smokexit parameter
  if (isSmokeExit) searchParams.append("smokexit", "true");
  // Append parameters to url
  const params = searchParams.toString();
  if (params.length > 0) address += "?" + params;
  return address;
};

function getPatientWithId(patientId: number, selectedNodes?: string[]) {
  return (data: any): Promise<PatientData> => {
    return get<any>(fullEndpoint(patientId, selectedNodes), undefined);
  };
}

function putPatientWithId(patientId: number) {
  return (data: any): Promise<PatientData> => {
    return put<any>(fullEndpoint(patientId), data, undefined);
  };
}

export function useGetPatient(patientId: number, selectedNodes?: string[]) {
  return makeRequestHook<any, {}>(getPatientWithId(patientId, selectedNodes))(
    {}
  );
}

export function usePutPatient(patientId: number) {
  return makeRequestHook<any, {}>(putPatientWithId(patientId))({});
}
