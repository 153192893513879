import React from "react";
import { createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const PUBLIC_URL = process.env.PUBLIC_URL;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      letterSpacing: "-0.01em"
    },
    logoText: {
      display: "inline-block",
      color: "black",
      textDecoration: "none",
      position: "relative",
      marginLeft: "0.1em"
    },
    kuuraFont: {
      fontWeight: 700
    },
    serviceFont: {
      fontWeight: 300
    },
    logoImage: {
      height: "auto"
    },
    lineImage: {
      display: "block",
      position: "absolute",
      width: "100%",
      bottom: "0.05em"
    }
  })
);

export default function LogoWithText(props: any) {
  let { fontSize, imageSize, ...rest } = props;
  const classes = useStyles();
  const serviceText =
    process.env.REACT_APP_SMOKE_EXIT === "true" ? "Seuranta" : "Etälääkäri";
  fontSize = fontSize || "1em";
  // If imageSize is not specified use double the font
  imageSize = imageSize || "2em";
  // Pass rest of the props to first div so LogoWithText margins etc. can be
  // adjusted from the parent
  return (
    <div {...rest}>
      <div className={classes.logo} style={{ fontSize: fontSize }}>
        <img
          className={classes.logoImage}
          style={{ width: imageSize }}
          src={`${PUBLIC_URL}/assets/kuura-logo-no-text.png`}
          alt="Kuura logo"
        />
        <div className={classes.logoText}>
          <span className={classes.kuuraFont}>Kuura</span>
          <span className={classes.serviceFont}>{serviceText}</span>
          <img
            className={classes.lineImage}
            src={`${PUBLIC_URL}/assets/line-under-logo.png`}
            alt="Line under the logo"
          />
        </div>
      </div>
    </div>
  );
}
