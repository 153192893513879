import React, { memo } from "react";
import { createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TicketComment } from "../../api/ticket";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: theme.palette.text.primary
    },
    editInfo: {
      color: theme.palette.text.primary,
      fontStyle: "italic",
      fontSize: 14
    }
  })
);

export interface TicketCommentItemProps {
  key: string;
  item: TicketComment;
}

function TicketCommentItem(props: TicketCommentItemProps) {
  const classes = useStyles();
  const item = props.item;

  return (
    <div className={classes.root}>
      <div className={classes.editInfo}>
        {`${item.user}, ${item.createdAt}`}
      </div>
      <div>{item.text}</div>
    </div>
  );
}

export default memo(TicketCommentItem);
