import React, { useState, useEffect, useReducer } from "react";
import { Link, Breadcrumbs, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  AnswerOption as IAnswerOption,
  QuestionData
} from "../../api/getQuestion";
import BotMessage from "./BotMessage";
import { Redirect } from "react-router-dom";
import RoundButton from "../../components/RoundButton";
import {
  usePatientDispatch,
  ActionTypes as PatientActionTypes,
  usePatientState
} from "../../api/PatientProvider";
import { usePostPatient } from "../../api/patients";
import AnswerOption from "./AnswerOption";
import Alert from "@material-ui/lab/Alert";
import { isSmokeExit } from "../../config";
import { fmtStdDateStr } from "../../utils/date";
import { isDate } from "date-fns";

const PUBLIC_URL = process.env.PUBLIC_URL;

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "70px",
      paddingRight: "70px",
      maxWidth: "800px",
      margin: "auto",
      [breakpoints.down("xs")]: {
        maxWidth: "350px",
        padding: 0
      }
    },
    breadcrumbs: {
      marginBottom: "2rem",
      "& a": {
        cursor: "pointer",
        opacity: "0.7",
        transition: "opacity 0.2s",
        "&:hover": {
          textDecoration: "none",
          opacity: "1"
        }
      }
    },
    botMessagesBlock: {
      maxWidth: "500px",
      "& > *": {
        margin: "0.5rem",
        [breakpoints.down("xs")]: {
          margin: 0
        }
      }
    },
    avatarHeading: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "1rem",
      "& span": {
        color: "#999",
        fontSize: "0.8rem",
        marginLeft: "0.5rem"
      },
      "& img": {
        flex: 0,
        width: "auto",
        height: "20px"
      }
    },
    answerOptionsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      marginTop: "2rem",
      marginBottom: "2rem"
    },
    bottomDiv: {
      [breakpoints.down("xs")]: {
        // This is a hack
        // The scrollable view seems to mess with styling a bit,
        // so we need to adjust the horizontal position for mobile
        position: "relative",
        left: "-150px"
        // top: "-50px"
      }
    }
  })
);

export enum SurveyActionTypes {
  CompleteSurvey,
  TransferPatient,
  Redirect
}

export type SurveyAction = {
  type: SurveyActionTypes;
  messages?: QuestionData[];
  answerId?: number;
  redirect?: string;
};

type SurveyState = {
  answerIdForPatientTransfer: number | null;
  redirect: string | null;
};

const getAnswerIdForPatientTransfer = (messages: QuestionData[]) =>
  messages.slice(-1)[0].answerId;

function surveyReducer(state: SurveyState, action: SurveyAction): SurveyState {
  switch (action.type) {
    case SurveyActionTypes.TransferPatient:
      return action.answerId
        ? {
            ...state,
            answerIdForPatientTransfer: action.answerId
          }
        : state;
    case SurveyActionTypes.Redirect:
      return action.redirect ? { ...state, redirect: action.redirect } : state;
    default:
      return state;
  }
}

// Returns surveyFinished value for last question or null if none present
const getSurveyFinished = (messages: QuestionData[]) =>
  (messages.length > 0 && messages[messages.length - 1].surveyFinished) || null;

export default function BotView({
  title,
  isLinkView,
  toggleView,
  messages,
  answers,
  setAnswer,
  refMessagesDiv
}: {
  title: string;
  isLinkView: boolean;
  toggleView: () => void;
  messages: QuestionData[];
  answers: IAnswerOption[];
  setAnswer: (answer: IAnswerOption) => void;
  refMessagesDiv: any;
}) {
  const classes = useStyles();
  const patientState = usePatientState();
  const patientDispatch = usePatientDispatch();
  const postPatient = usePostPatient();
  const reqPostPatient = postPatient.request;
  const [surveyState, surveyDispatch] = useReducer(surveyReducer, {
    answerIdForPatientTransfer: null,
    redirect: null
  });
  const answerForIdx = (idx: number) =>
    answers[idx].ansValue || answers[idx].text;
  // const [textInput, setTextInput] = useState<string>("");
  const [answerInput, setAnswerInput] = useState<any>("");
  const [waitingForRedirect, setWaitingForRedirect] = useState<
    "finished" | "reset" | "createPatient" | "url" | null
  >(null);

  // Handle patient transfer
  useEffect(() => {
    const shouldCreatePatient = getSurveyFinished(messages) === "createPatient";

    if (shouldCreatePatient && !surveyState.answerIdForPatientTransfer) {
      const answerId = getAnswerIdForPatientTransfer(messages);
      surveyDispatch({ type: SurveyActionTypes.TransferPatient, answerId });
      reqPostPatient({ answerId });
    }
  }, [messages, surveyState.answerIdForPatientTransfer, reqPostPatient]);

  // postPatient.data has changed
  // --> update userState with userDispatch
  useEffect(() => {
    if (postPatient.data) {
      patientDispatch({
        type: PatientActionTypes.UpdatePatient,
        patient: postPatient.data
      });
    }
  }, [postPatient.data, patientDispatch]);

  // Handle redirects
  useEffect(() => {
    const surveyFinished = getSurveyFinished(messages);
    const isNewPatientCreated = postPatient.data && patientState.patient;
    const isStraightRedirect = surveyFinished === "url";
    let isMounted = true;

    if (isNewPatientCreated || isStraightRedirect) {
      setWaitingForRedirect(isNewPatientCreated ? "createPatient" : "url");
      setTimeout(() => {
        if (isMounted) {
          isNewPatientCreated
            ? surveyDispatch({
                type: SurveyActionTypes.Redirect,
                redirect: "/potilaskortti"
              })
            : surveyDispatch({
                type: SurveyActionTypes.Redirect,
                redirect: messages[messages.length - 1].url
              });
        }
      }, 2000);
    }

    return () => {
      isMounted = false;
    };
  }, [messages, patientState.patient, postPatient.data]);

  return surveyState.redirect ? (
    <Redirect to={surveyState.redirect} />
  ) : (
    <div className={classes.root}>
      {!isLinkView && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className={classes.breadcrumbs}
        >
          <Link color="inherit" onClick={toggleView}>
            {isSmokeExit ? "Aloitus" : "Oirehaku"}
          </Link>
          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
      )}
      <div>
        {messages.map((message, idx) => (
          <div key={idx} className="bot-message-block">
            <div className={classes.avatarHeading}>
              <img
                src={
                  isSmokeExit
                    ? `${PUBLIC_URL}/images/jyvaskyla.svg`
                    : `${PUBLIC_URL}/assets/kuura-logo-no-text.png`
                }
                alt="Botti"
              />
              <span>
                {isSmokeExit ? "jyvaskyla.terveyspalvelut" : "Digilääkäri"}
              </span>
            </div>
            <div className={classes.botMessagesBlock}>
              {message.questions.map((question, idx) => (
                <BotMessage key={idx} message={question} />
              ))}
            </div>
            {answers[idx] ? (
              <div className={`answered ${classes.answerOptionsContainer}`}>
                <RoundButton
                  key={idx}
                  selected={true}
                  onClick={() => setAnswer(answers[idx])}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: isDate(answerForIdx(idx))
                        ? fmtStdDateStr(answerForIdx(idx), false)
                        : answerForIdx(idx)
                    }}
                  />
                </RoundButton>
              </div>
            ) : (
              <div
                className={`answerOptions ${classes.answerOptionsContainer}`}
              >
                {message.answerOptions.length > 0 &&
                  message.answerOptions[0].id >= 0 &&
                  message.answerOptions.map((answerOption, idx) => (
                    <AnswerOption
                      key={idx}
                      answerOption={answerOption}
                      setAnswer={setAnswer}
                      answerInput={answerInput}
                      setAnswerInput={setAnswerInput}
                    />
                  ))}
              </div>
            )}
          </div>
        ))}
        {waitingForRedirect && (
          <Alert severity="info">
            {waitingForRedirect === "createPatient"
              ? "Tiedot siirtyvät lääkärille, potilaskortti avautuu"
              : "Ohjaamme sinut oikealle sivulle, hetki"}
          </Alert>
        )}
        <div ref={refMessagesDiv} className={classes.bottomDiv}></div>{" "}
        {/* Used to scroll to bottom */}
      </div>
    </div>
  );
}
