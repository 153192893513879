import React, { useState, useEffect } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import LiveDiscussion from "./LiveDiscussion";
import Anamnesis from "./Anamnesis";
import PatientInfo from "./PatientInfo";
import SEPatientInfo from "./SEPatientInfo";
import {
  usePatientState,
  usePatientDispatch,
  ActionTypes as PatientActionTypes,
  Dispatch as PatientDispatch
} from "../../api/PatientProvider";
import { useUserState, useUserDispatch } from "../../api/UserProvider";
import { useGetPatient, usePutPatient, PatientData } from "../../api/patient";
import { useGetNurses, NurseData } from "../../api/nurses";
import Loading from "../../components/Loading";
import paths from "../../paths";
import BasicInfo from "./BasicInfo";
import StatusUpdate from "./StatusUpdate";
import { isSmokeExit } from "../../config";
import SMSHistory from "./SMSHistory";
import { SEProvider } from "./SEProvider";
import Tickets from "./Tickets";
import LatestSurveyResults from "./LatestSurveyResults";
import PatientDataInfo from "./PatientDataInfo";

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    root: {
      paddingLeft: "30px",
      paddingRight: "30px",
      maxWidth: "1400px",
      [breakpoints.down("sm")]: {
        width: "100%",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& > div.MuiGrid-root": {
          flexDirection: "column",
          padding: "0.5rem",
          "& > div.MuiGrid-item": {
            maxWidth: "100%"
          }
        }
      }
    },
    grid: {
      padding: "1rem"
    },
    bottomSection: {
      padding: "0 2rem"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    hidden: {
      display: "none"
    }
  })
);

// Handle patient data updates and queries
function usePatient(patient: PatientData, dispatch: PatientDispatch) {
  const [loading, setLoading] = useState(false);
  const { request: getRequest, data } = useGetPatient(
    patient.id,
    isSmokeExit
      ? [
          "status",
          "halukkuus",
          "askivuodet",
          "resepti",
          "lopetus",
          "lopetuspvm"
        ]
      : undefined
  );
  const { request: putRequest } = usePutPatient(patient.id);

  // Initial data fetching
  useEffect(() => {
    getRequest({});
  }, [getRequest]);

  // Patient data has changed
  useEffect(() => {
    // TODO: improve on this
    if (Object.keys(data).length > 1) {
      setLoading(false);
      dispatch({
        type: PatientActionTypes.UpdatePatient,
        patient: data
      });
    }
  }, [data, dispatch]);

  // PUT request issued
  const update = (payload: Record<string, any>) => {
    const updated = { ...patient, ...payload } as PatientData;

    putRequest(payload);
    dispatch({
      type: PatientActionTypes.UpdatePatient,
      patient: updated
    });
    return updated;
  };

  return { update, loading };
}

// Get list of all nurses
function useNurses(): NurseData[] {
  const { data, request } = useGetNurses();

  // Initial data fetching
  useEffect(() => {
    request({});
  }, [request]);

  return data;
}

// Returns (in order of preference):
// ... the patient ID from the URL params,
// ... the patient data passed in,
// ... or a PatientData object with an invalid ID
function getActivePatient(
  patient: PatientData | null,
  urlParams: Record<string, any>
): PatientData {
  const urlId = parseInt(urlParams.patientId);
  const samePatientId = patient?.id === urlId;

  if (urlId > 0 && !samePatientId) {
    return { id: urlId } as PatientData;
  } else {
    return patient?.id ? patient : ({ id: -1 } as PatientData);
  }
}

// If user should be redirected to another address, return that as a string
// If not, return undefined
function getRedirectAddress(
  patientId: number,
  urlParams: Record<string, any>
): string | undefined {
  const root = "/";

  // Invalid ID / patient object
  if (!patientId || patientId === -1) return root;

  // If no patientId param is specified, then redirect to the currently active one
  // If no param specified and there is no active page, then redirect to root
  if (!urlParams.patientId) {
    return patientId ? `${paths.patientCard}/${patientId}` : root;
  }

  // Everything good -> should not redirect at all
  return undefined;
}

// Parse patient name from patient data
function getPatientName(patient: PatientData): string | undefined {
  const regex = /(etunimi|sukunimi)/g;
  const match = patient.allAnswers?.find(item => item.question.match(regex));
  return match?.answer;
}

const getPatientHetu = (patient: PatientData) =>
  patient.selectedNodes?.hetu?.writtenAnswer;

const getPatientDecursus = (patient: PatientData) =>
  patient.selectedNodes?.decursus?.questionText;

// Displays either the active patient's page (from UserProvider) or one that matches the URL
function PatientCardPage({
  userState,
  userDispatch,
  patientState,
  patientDispatch,
  updatePatient,
  urlParams,
  patient,
  nurses
}: any) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const derived = {
    nurse:
      nurses.find((nurse: NurseData) => nurse.id === patient.nurseId) ||
      ({} as NurseData),
    hetu: getPatientHetu(patient),
    name: getPatientName(patient),
    decursus: getPatientDecursus(patient)
  };
  const staff = {
    nurse: derived.nurse.nurse,
    doctor: patient.clinicianName
  };

  return (
    <Box className={classes.root}>
      <BasicInfo
        userState={userState}
        updatePatient={updatePatient}
        nurses={nurses}
        patient={patient}
        staff={staff}
      />
      <Grid container>
        <Grid
          container
          item
          alignContent="flex-start"
          spacing={2}
          xs={12}
          md={6}
          className={classes.grid}
        >
          <Grid item xs={12}>
            <PatientDataInfo />
          </Grid>
          <Grid item xs={12}>
            {isSmokeExit && <Tickets />}
          </Grid>
          <Grid item xs={12}>
            {isSmokeExit ? (
              <SMSHistory />
            ) : (
              <React.Fragment>
                {!isMobile && <StatusUpdate updatePatient={updatePatient} />}
                <LiveDiscussion />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignContent="flex-start"
          xs={12}
          md={6}
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12}>
            {isMobile && <StatusUpdate updatePatient={updatePatient} />}
            {isSmokeExit ? (
              <SEPatientInfo />
            ) : (
              <PatientInfo
                name={derived.name}
                hetu={derived.hetu}
                decursus={derived.decursus}
              />
            )}
          </Grid>
          {isSmokeExit && (
            <Grid item xs={12}>
              <LatestSurveyResults />
            </Grid>
          )}
          <Grid item xs={12}>
            <Anamnesis />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function PageWithContext(props: any) {
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const patientState = usePatientState();
  const patientDispatch = usePatientDispatch();
  const urlParams = useParams<Record<string, any>>();
  const patient = getActivePatient(patientState.patient, urlParams);
  const nurses = useNurses();
  const { update: updatePatient, loading: loadingGetReq } = usePatient(
    patient,
    patientDispatch
  );
  const loading = loadingGetReq || !patient.allAnswers;
  const redirectAddress = getRedirectAddress(patient.id, urlParams);

  if (redirectAddress) return <Redirect to={redirectAddress} />;
  else if (loading) return <Loading />;

  return isSmokeExit ? (
    <SEProvider patientId={patient.id}>
      <PatientCardPage
        userState={userState}
        userDispatch={userDispatch}
        patientState={patientState}
        patientDispatch={patientDispatch}
        urlParams={urlParams}
        patient={patient}
        updatePatient={updatePatient}
        nurses={nurses}
        {...props}
      />
    </SEProvider>
  ) : (
    <PatientCardPage
      userState={userState}
      userDispatch={userDispatch}
      patientState={patientState}
      patientDispatch={patientDispatch}
      urlParams={urlParams}
      patient={patient}
      updatePatient={updatePatient}
      nurses={nurses}
      {...props}
    />
  );
}
