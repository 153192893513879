import { makeRequestHook } from "../utils/requestHook";
import { get, post } from "../utils/httpUtils";
import { QuestionData } from "./getQuestion";
import { isSmokeExit } from "../config";
import { Smokexit } from "./patient";

const endpoint = "/v1/patient";

export type PatientsData = {
  id: number;
  name: string;
  nurseStatus: number;
  nurseLastUpdate: string | null;
  clinicianStatus: number;
  clinicianLastUpdate: string | null;
  createdAt: string;
  clinician: string;
  notification: boolean;
  smokexit?: Smokexit;
  data: Record<string, any> | null;
};

export type PatientSubmission = {
  id: number;
};

const initialValue: {
  getPatients: null;
  postPatient: null;
} = {
  getPatients: null,
  postPatient: null
};

export function getPostPatientPayload(question: QuestionData) {
  return {
    answerId: question.answerId
  };
}

function getPatients(data: any): Promise<PatientsData[]> {
  const smokexit = isSmokeExit ? { smokexit: "true" } : undefined;
  return get<any>(endpoint, smokexit);
}

function postPatient(data: any): Promise<PatientSubmission> {
  return post<any>(endpoint, data, undefined);
}

export function useGetPatients() {
  return makeRequestHook<any, {}>(getPatients)(initialValue.getPatients);
}

export function usePostPatient() {
  return makeRequestHook<any, Record<string, any>>(postPatient)(
    initialValue.postPatient
  );
}
