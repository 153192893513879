import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Toolbar,
  AppBar,
  Typography,
  Button,
  IconButton,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { NavLink, useLocation, Link } from "react-router-dom";
import { pick } from "ramda";
import paths from "../paths";
import { useLogout } from "../api/Authorization/Authentication";
import { useUserState } from "../api/UserProvider";
import { usePatientState } from "../api/PatientProvider";
import LogoWithText from "./LogoWithText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useGetNotification } from "../api/notification";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { isSmokeExit } from "../config";

const useStyles = makeStyles(({ palette, breakpoints }: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      color: palette.primary.main,
      maxHeight: "48px" // for some reason, `dense` specifies "min-height" instead of "max-height"
    },
    appBar: {
      backgroundColor: palette.background.paper
    },
    logoLink: {
      textDecoration: "none",
      marginRight: "175px",
      [breakpoints.down("md")]: {
        marginRight: 0
      },
      [breakpoints.down("sm")]: {
        display: "none"
      }
    },
    kuuraLogo: {
      maxHeight: "48px"
    },
    tabs: {
      textTransform: "none",
      backgroundColor: palette.background.paper
    },
    tab: {
      textTransform: "none",
      fontSize: "1rem"
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
      [breakpoints.down("sm")]: {
        padding: 0
      },
      [breakpoints.down("xs")]: {
        minWidth: 0,
        marginLeft: "1rem",
        "& span.MuiButton-label": {
          width: "max-content"
        },
        "& span.MuiTouchRipple-root": {
          display: "none"
        }
      }
    },
    user: {
      [breakpoints.down("sm")]: {
        display: "none !important"
      }
    },
    right: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "300px",
      color: palette.text.primary,
      [breakpoints.down("sm")]: {
        width: "auto"
      },
      "& > span": {
        display: "flex",
        flexDirection: "row",
        marginRight: "1rem"
      }
    },
    notificationBell: {
      "& button": {
        padding: "5px"
      }
    }
  })
);

const pageLinks: Record<string, any> = isSmokeExit
  ? {
      Potilaat: {
        path: paths.patients
      },
      Potilaskortti: {
        path: paths.patientCard
      }
    }
  : {
      "Uusi potilas": {
        path: paths.newPatient,
        // use root if neeeded, as paths.newPatient will be regex-matched with paths.survey, "/(uusi/kysely)"
        altLink: paths.root
      },
      Potilaat: {
        path: paths.patients
      },
      Potilaskortti: {
        path: paths.patientCard
      }
    };

function getIndexFromPathName(
  links: Record<string, any>,
  pathname: string
): number {
  const keys = Object.keys(links);

  // All regex matches
  const matches = keys.filter(key => {
    return pathname.match(links[key].path);
  });

  // Get "best" match (by path specificity <-> length)
  const best = matches.sort((a, b) => b.length - a.length)[0];
  return keys.indexOf(best);
}

const prettifiedRoles: Record<string, string> = {
  DOCTOR: "Lääkäri",
  NURSE: "Hoitaja"
};

export default function Header(props: any) {
  const classes = useStyles();
  const userState = useUserState();
  const patientState = usePatientState();
  const patientNotificationState = patientState.patient?.notification;
  const pathname = useLocation().pathname;
  const renderablePageLinks = patientState.patient
    ? pageLinks
    : pick(["Uusi potilas", "Potilaat"], pageLinks);
  const pathIdx = getIndexFromPathName(renderablePageLinks, pathname);
  const [tab, setTab] = useState(pathIdx);
  const userData = {
    name: (userState.user && userState.user.firstname) || "",
    role: (userState.user && prettifiedRoles[userState.user.usertype]) || ""
  };
  const {
    request: notificationReq,
    data: notificationData
  } = useGetNotification();

  // Update notification bell
  useEffect(() => {
    notificationReq({});
  }, [notificationReq, patientNotificationState]);

  useEffect(() => {
    if (pathIdx >= 0) {
      setTab(pathIdx);
    }
  }, [pathIdx]);

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Link to="/" className={classes.logoLink}>
          <LogoWithText fontSize={"1.3em"} />
        </Link>
        {/* Only render tabs if more than one, and if we know which page we should be on */}
        {Object.keys(renderablePageLinks).length > 1 && tab >= 0 && (
          <Tabs
            value={tab}
            onChange={(event, value) => setTab(value)}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {Object.keys(renderablePageLinks).map((label, idx) => (
              <Tab
                label={label}
                key={idx}
                to={
                  pageLinks[label].altLink && pathname !== pageLinks[label].path
                    ? pageLinks[label].altLink
                    : pageLinks[label].path
                }
                component={NavLink}
                className={classes.tab}
              />
            ))}
          </Tabs>
        )}
        <div className={classes.right}>
          {/* If any patient has notification, show icon */}
          {isSmokeExit && notificationData?.notification && (
            <span className={classes.notificationBell}>
              <Link to="/potilaat">
                <IconButton>
                  <NotificationsIcon />
                </IconButton>
              </Link>
            </span>
          )}

          <span className={classes.user}>
            {userData.name !== "" && userData.role !== "" && (
              <Typography>{`${userData.role} ${userData.name}`}</Typography>
            )}
          </span>
          {process.env.REACT_APP_AUTH0_LOGIN === "true" ? (
            <Auth0Logout />
          ) : (
            <LocalLogout />
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

function LocalLogout() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading, request } = useLogout();

  return (
    <Button
      className={classes.button}
      disabled={loading}
      onClick={() => request()}
    >
      {isMobile ? <ExitToAppIcon /> : "Kirjaudu ulos"}
    </Button>
  );
}

function Auth0Logout() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <form action="/logout" method="POST">
      <input
        type="hidden"
        name="redirectTo"
        value={window.location.href || "/"}
      />
      <Button type="submit" className={classes.button}>
        {isMobile ? <ExitToAppIcon /> : "Kirjaudu ulos"}
      </Button>
    </form>
  );
}
