import React, { useCallback, useEffect } from "react";
import { createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import { useForm } from "react-hook-form";
import { TicketComment, useAddCommentToTicket } from "../../api/ticket";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "1rem"
    },
    input: {
      flex: "1",
      marginRight: theme.spacing(1)
    },
    button: {
      marginTop: "8px",
      marginRight: "0.5rem"
    }
  })
);

export interface TicketCommentFormProps {
  ticketId: string;
  onCommentCreated: (comment: TicketComment) => void;
  onCancel: () => void;
}

export default function TicketCommentForm(props: TicketCommentFormProps) {
  const classes = useStyles();
  const ticketId = props.ticketId;
  const onCommentCreated = props.onCommentCreated;
  const { register, setValue, errors, handleSubmit } = useForm<{
    comment: string;
  }>();
  const addCommentToTicketData = useAddCommentToTicket();
  const { counter, error, loading, data, request } = addCommentToTicketData;
  const isSubmitDisabled =
    addCommentToTicketData.loading || (!!errors && !!errors.comment);

  const addComment = useCallback(
    (formData: { comment: string }) => {
      if (!isSubmitDisabled) {
        request({ id: ticketId, comment: formData.comment });
      }
    },
    [request, isSubmitDisabled, ticketId]
  );

  useEffect(() => {
    if (counter > 0 && !error) {
      if (data) {
        onCommentCreated(data.comment);
      }
      setValue("comment", "");
    }
  }, [counter, error, data, setValue, onCommentCreated]);

  return (
    <form onSubmit={handleSubmit(addComment)}>
      <div className={classes.root}>
        <TextField
          variant="outlined"
          disabled={loading}
          error={!!errors && !!errors.comment}
          className={classes.input}
          aria-describedby="comment"
          placeholder={"Kommentti"}
          name="comment"
          helperText={((errors || {}).comment || {}).message}
          inputRef={register({ required: "Kommentti puuttuu" })}
        />
        <Button
          className={classes.button}
          color="primary"
          disabled={loading}
          onClick={() => {
            setValue("comment", "");
            props.onCancel();
          }}
        >
          Peruuta
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isSubmitDisabled}
          onClick={handleSubmit(addComment)}
        >
          Tallenna
        </Button>
      </div>
    </form>
  );
}
