// Original source: kuura/admin-panel

import { get, post, put } from "../utils/httpUtils";
import { makeRequestHook } from "../utils/requestHook";

const endpointBase = "/ticket";

/*
 * Types
 */

export interface Ticket {
  id: string;
  type: TicketType;
  title: string;
  text: string;
  path: string;
  status: TicketStatus;
  createdAt: string;
  comments: Array<TicketComment> | null;
  phoneNumber?: string;
}

export interface TicketComment {
  text: string;
  user: string;
  createdAt: string;
}

export enum TicketStatus {
  Open = "OPEN",
  InProgress = "IN PROGRESS",
  Closed = "CLOSED"
}

export enum TicketType {
  Draft = "DRAFT",
  Idea = "IDEA",
  Feedback = "FEEDBACK",
  Question = "QUESTION"
}

export interface AddCommentToTicketResponse {
  comment: TicketComment;
}

export interface CreateOnePayload {
  type: string;
  title: string;
  text: string;
  path: string;
  status: TicketStatus;
}

export interface CreateOneResponse {
  id: string;
}

export interface GetAllResponse {
  total: number;
  tickets: Array<Ticket>;
}

export interface UpdateStatusResponse {
  statusUpdated: boolean;
}

/*
 * Handlers
 */

function addCommentToTicket(
  id: string,
  comment: string
): Promise<AddCommentToTicketResponse> {
  return put<AddCommentToTicketResponse>(`${endpointBase}/${id}/comment`, {
    id,
    comment
  });
}

function createOne(payload: CreateOnePayload): Promise<CreateOneResponse> {
  return post(endpointBase, payload);
}

function getAll(): Promise<GetAllResponse> {
  return get<GetAllResponse>(endpointBase);
}

function filterManyByStatus(
  filterStatus?: TicketStatus
): Promise<GetAllResponse> {
  return getAll().then(response => {
    if (!filterStatus) {
      return response;
    }

    const filteredTickets = response.tickets.filter(
      ticket => ticket.status === filterStatus
    );

    return {
      total: filteredTickets.length,
      tickets: filteredTickets
    };
  });
}

function updateStatus(
  id: string,
  status: TicketStatus
): Promise<UpdateStatusResponse> {
  return put<UpdateStatusResponse>(`${endpointBase}/${id}/status`, {
    id,
    status
  });
}

/*
 * Hooks
 */

export function useAddCommentToTicket() {
  return makeRequestHook<
    AddCommentToTicketResponse | null,
    { id: string; comment: string }
  >(p => addCommentToTicket(p.id, p.comment))(null);
}

export function useCreateOne() {
  return makeRequestHook<CreateOneResponse | null, CreateOnePayload>(createOne)(
    null
  );
}

export function useUpdateStatus() {
  return makeRequestHook<
    UpdateStatusResponse | null,
    { id: string; status: TicketStatus }
  >(p => updateStatus(p.id, p.status))(null);
}

export function useGetAll() {
  return makeRequestHook<GetAllResponse, void>(getAll)({
    total: 0,
    tickets: []
  });
}

export function useFilterManyByStatus() {
  return makeRequestHook<GetAllResponse, { filterStatus?: TicketStatus }>(p =>
    filterManyByStatus(p.filterStatus)
  )({ total: 0, tickets: [] });
}
