import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { isValid } from "../../utils/validation";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    table: {
      width: "100%"
    },
    tableHead: {
      backgroundColor: "#f8f8f8",
      color: palette.common.white
    },
    answer: {
      whiteSpace: "pre-wrap",
      width: "25%"
    },
    question: {
      whiteSpace: "pre-wrap",
      width: "75%"
    }
  })
);

type AnamnesisTableProps = {
  patientId: number;
  qa: Array<{ question: string; answer: string }>;
};

export default function AnamnesisTable({ patientId, qa }: AnamnesisTableProps) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="anamnesis" size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Kysymys</TableCell>
            <TableCell>Vastaus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {qa.map((row: Record<string, any>, idx: number) => (
            <TableRow key={idx}>
              <TableCell
                dangerouslySetInnerHTML={{ __html: row.question }}
                className={classes.question}
              />
              <TableCell className={classes.answer}>
                {isValid(row.answer) ? (
                  row.answer
                ) : (
                  <CheckBoxIcon color="secondary" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
