import React, { useState, useRef } from "react";
import {
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    popper: {
      zIndex: 1000
    },
    menuList: {
      maxHeight: 300,
      overflowY: "scroll"
    }
  })
);

type Dict = Record<string, any>;

interface DropdownProps {
  color?: "inherit" | "primary" | "secondary" | "default";
  size?: "small" | "medium" | "large";
  label: string;
  onIndexChanged?: (idx: number) => void;
  onMenuItemClick?: (idx: number) => void;
  menuItems: Dict[];
  getMenuItemProps: (menuItem: any) => Dict;
  className: string;
  selectedItem?: number;
}

export default function Dropdown(
  props: React.HTMLAttributes<HTMLElement> & DropdownProps
) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(props.selectedItem ? props.selectedItem : 0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    idx: React.SetStateAction<number>
  ) => {
    if (props.onIndexChanged && idx !== selectedIndex) {
      props.onIndexChanged(idx as number);
    }
    if(props.onMenuItemClick) {
      props.onMenuItemClick(idx as number);
    }
    setSelectedIndex(idx);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        color={props.color || "primary"}
        size={props.size || "medium"}
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label={"dropdown button"}
        onClick={handleToggle}
        ref={anchorRef}
        className={props.className}
      >
        {props.label}
        <ArrowDropDownIcon />
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="dropdown-menu" className={classes.menuList}>
                  {props.menuItems.map((item: Dict, idx: number) => (
                    <MenuItem
                      key={idx}
                      value={props.getMenuItemProps(item).value}
                      selected={idx === selectedIndex}
                      onClick={event => handleMenuItemClick(event, idx)}
                    >
                      {props.getMenuItemProps(item).label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
