import React from "react";
import { Box, Card, Typography } from "@material-ui/core";
import AnamnesisTable from "./AnamnesisTable";
import { isSmokeExit } from "../../config";
import { usePatientState } from "../../api/PatientProvider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AnamnesisPanel from "./AnamnesisPanel";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      marginBottom: "1rem"
    },
    card: {
      padding: "1rem"
    }
  })
);

export default function Anamnesis() {
  const classes = useStyles();
  const patientState = usePatientState();
  const responses = patientState.patient?.responses || [];
  const patientId = patientState.patient?.id;

  if (patientId === undefined) {
    console.error("Could not render Anamnesis: invalid patient id");
    return <div></div>;
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h6" component="h3" className={classes.heading}>
          {isSmokeExit ? "Kyselyhistoria" : "Anamneesi"}
        </Typography>
        {isSmokeExit ? (
          <AnamnesisPanel patientId={patientId} responses={responses} />
        ) : (
          <AnamnesisTable
            patientId={patientId}
            qa={responses[0]?.allAnswers || []}
          />
        )}
      </Card>
    </Box>
  );
}
