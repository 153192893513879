import { makeRequestHook } from "../utils/requestHook";
import { get } from "../utils/httpUtils";

const endpoint = "/v1/patients/notification";

function getNotification(): Promise<boolean> {
  return get<any>(endpoint, undefined);
}

export function useGetNotification() {
  return makeRequestHook<any, {}>(getNotification)(undefined);
}
