import Cookies from "universal-cookie";
import { useContext, useEffect } from "react";
import AuthContext from "./AuthContext";
import { makeRequestHook } from "../../utils/requestHook";
import { get, post } from "../../utils/httpUtils";

export function isAuthenticated(): boolean {
  const cookies = new Cookies();
  return !!cookies.get("username") && !!cookies.get("role");
}

export type UserCredentials = { username: string; password: string };

const useLoginRequest = makeRequestHook<any, UserCredentials>(p =>
  post<any>("/login", p)
);

export function useLogin() {
  const { checkAuthentication } = useContext(AuthContext);

  const loginRequestData = useLoginRequest(undefined);

  const counter = loginRequestData.counter;

  useEffect(() => {
    if (counter > 0) {
      checkAuthentication();
    }
  }, [counter, checkAuthentication]);

  return loginRequestData;
}

const useLogoutRequest = makeRequestHook<any, void>(() => get<any>("/logout"));

export function useLogout() {
  const { checkAuthentication } = useContext(AuthContext);

  const { loading, data, error, request, counter } = useLogoutRequest(
    undefined
  );

  useEffect(() => {
    if (counter > 0) {
      checkAuthentication();
    }
  }, [counter, checkAuthentication]);

  return { loading, data, error, request };
}
