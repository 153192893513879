import React from "react";
import { Typography } from "@material-ui/core";

export default function BotMessage({
  message
}: {
  message: Record<string, any>;
}) {
  return (
    <div>
      <Typography>
        <span dangerouslySetInnerHTML={{ __html: message.text }} />
      </Typography>
    </div>
  );
}
