import React, { useRef } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { isValid } from "../../utils/validation";

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      marginBottom: "30px"
    },
    patientInfo: {
      "& strong, & span.patient-name": {
        marginRight: "10px"
      }
    },
    copyButton: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: "0.25rem"
    },
    lowerGrid: {
      margin: "30px 0 0 0",
      maxWidth: "1400px"
    },
    modalButton: {
      padding: "6px 0"
    }
  })
);

export default function PatientInfo({ hetu, name, decursus }: any) {
  const classes = useStyles();
  const vhetu = isValid(hetu) ? hetu : undefined;
  const vname = isValid(name) ? name : undefined;

  // For copying to clipboard
  const refHetu = useRef<any>(null);
  const refDecursus = useRef<any>(null);

  const copyToClipboard = (item: "hetu" | "decursus") => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const sel = window.getSelection();
    if (!sel) return;
    sel.removeAllRanges();

    let range = document.createRange();
    if (item === "hetu") {
      range.selectNode(refHetu.current);
    } else {
      range.selectNode(refDecursus.current);
    }

    sel.addRange(range);
    document.execCommand("copy");
    sel.removeAllRanges();
  };

  return (
    <div className={`patient-info ${classes.root}`}>
      {(vname || vhetu) && (
        <div className={classes.patientInfo}>
          <Typography>
            <strong>Potilas:</strong>
            {vname && <span className="patient-name">{vname}</span>}
            {vhetu && (
              <span className="patient-hetu">
                (hetu: <span ref={refHetu}>{vhetu}</span>)
              </span>
            )}
          </Typography>
          {vhetu && document.queryCommandSupported("copy") && (
            <Button
              className={classes.copyButton}
              size="small"
              color="primary"
              onClick={copyToClipboard("hetu")}
            >
              Kopioi henkilötunnus leikepöydälle
            </Button>
          )}
        </div>
      )}
      {decursus && (
        <div className={classes.lowerGrid}>
          <React.Fragment>
            <Typography variant="h6" component="h2">
              Potilaskertomus
            </Typography>
            <div
              ref={refDecursus}
              dangerouslySetInnerHTML={{ __html: decursus }}
            />
          </React.Fragment>
          {document.queryCommandSupported("copy") && (
            <Button
              className={classes.copyButton}
              size="small"
              color="primary"
              onClick={copyToClipboard("decursus")}
            >
              Kopioi potilaskertomus leikepöydälle
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
