import React, { useContext, useReducer, useEffect, useState } from "react";
import { SMS, useGetSMS } from "../../api/sms";
import { usePatientState } from "../../api/PatientProvider";

export enum ActionTypes {
  AddTexts
}
type Action = {
  type: ActionTypes;
  value: SMS[];
};
export type Dispatch = (action: Action) => void;
export type State = {
  texts: SMS[];
};
type ProviderProps = { patientId: number; children: React.ReactNode };

const SEStateContext = React.createContext<State | undefined>(undefined);
const SEDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionTypes.AddTexts:
      return { ...state, texts: [...action.value, ...state.texts] };
    default:
      return state;
  }
}

export function useSEState() {
  const context = useContext(SEStateContext);
  if (!context) {
    throw new Error("useSEState must be used inside SEContext");
  }
  return context;
}

export function useSEDispatch() {
  const context = useContext(SEDispatchContext);
  if (!context) {
    throw new Error("useSEDispatch must be used inside SEContext");
  }
  return context;
}

export function SEProvider({ children, patientId }: ProviderProps) {
  const getSMS = useGetSMS(patientId);
  const patientState = usePatientState();
  const [state, dispatch] = useReducer(reducer, {
    texts: []
  });
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && patientState.patient?.id) {
      getSMS.request({});
      setInitialized(true);
    }
  }, [getSMS, initialized, patientState.patient]);

  useEffect(() => {
    if (getSMS.data) {
      dispatch({ type: ActionTypes.AddTexts, value: getSMS.data as SMS[] });
    }
  }, [getSMS.data]);

  return (
    <SEStateContext.Provider value={state}>
      <SEDispatchContext.Provider value={dispatch}>
        {children}
      </SEDispatchContext.Provider>
    </SEStateContext.Provider>
  );
}
