import React, { useState } from "react";
import RoundButton from "../../components/RoundButton";
import { AnswerOption as IAnswerOption } from "../../api/getQuestion";
import { IconButton, TextField } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) =>
  createStyles({
    form: {
      width: "100%",
      margin: "0.5rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      [breakpoints.down("xs")]: {
        margin: 0
      }
    },
    textField: {
      width: "100%"
    },
    dateField: {
      width: "200px",
      "& input": {
        cursor: "pointer"
      }
    },
    numberField: {
      maxWidth: "200px",
      width: "100%"
    }
  })
);

type AnswerOptionProps = {
  answerOption: IAnswerOption;
  setAnswer: (answer: IAnswerOption) => void;
  answerInput: any;
  setAnswerInput: (value: React.SetStateAction<any>) => void;
  createPatient?: boolean;
};

type DateInputProps = {
  dateInput: MaterialUiPickersDate;
  setDateInput: (value: React.SetStateAction<MaterialUiPickersDate>) => void;
  setAnswerInput: (value: React.SetStateAction<any>) => void;
  submitButton: JSX.Element;
};

type RegularInputProps = {
  answerOption: IAnswerOption;
  answerInput: any;
  setAnswerInput: (value: React.SetStateAction<any>) => void;
  submitButton: JSX.Element;
};

function DateInput({
  dateInput,
  submitButton,
  setDateInput,
  setAnswerInput
}: DateInputProps) {
  const classes = useStyles();

  return (
    <DatePicker
      disableToolbar
      format={"do MMMM yyyy"}
      cancelLabel={"Peruuta"}
      value={dateInput}
      onChange={(date: MaterialUiPickersDate) => {
        setDateInput(date);
        setAnswerInput(date);
      }}
      TextFieldComponent={({ inputProps, onClick, inputRef, value }) => (
        <TextField
          className={classes.dateField}
          inputProps={inputProps}
          onClick={onClick}
          inputRef={inputRef}
          placeholder="Valitse ajankohta"
          value={value}
          InputProps={{
            endAdornment: submitButton
          }}
        />
      )}
    />
  );
}

function RegularInput({
  answerOption,
  answerInput,
  setAnswerInput,
  submitButton
}: RegularInputProps) {
  const classes = useStyles();

  return (
    <TextField
      placeholder={answerOption.props.placeholder || "Vastauksesi"}
      type={answerOption.props.type}
      className={
        answerOption.props.type === "number"
          ? classes.numberField
          : classes.textField
      }
      value={answerInput}
      variant={"standard"}
      autoFocus
      multiline={false}
      fullWidth={true}
      onChange={event => setAnswerInput(event.target.value)}
      InputProps={{
        endAdornment: submitButton
      }}
    />
  );
}

export default function AnswerOption({
  answerOption,
  setAnswer,
  answerInput,
  setAnswerInput
}: AnswerOptionProps) {
  const classes = useStyles();
  const [dateInput, setDateInput] = useState<MaterialUiPickersDate>(null);

  const handleTextSubmit = () => {
    setAnswer({
      ...answerOption,
      ansValue: answerInput
    });
    setAnswerInput(""); // reset text field
  };

  const handleButtonSubmit = () => {
    setAnswer(answerOption);
  };

  const submitButton = (
    <IconButton
      color="primary"
      aria-label="lähetä"
      onClick={() => handleTextSubmit()}
      disabled={answerInput.length === 0}
    >
      <SendIcon />
    </IconButton>
  );

  return answerOption.props.type ? (
    <form
      className={classes.form}
      onSubmit={event => {
        event.preventDefault();
        if (answerInput.length > 0) handleTextSubmit();
      }}
    >
      {answerOption.props.type === "date" ? (
        <DateInput
          dateInput={dateInput}
          setDateInput={setDateInput}
          setAnswerInput={setAnswerInput}
          submitButton={submitButton}
        />
      ) : (
        <RegularInput
          answerOption={answerOption}
          answerInput={answerInput}
          setAnswerInput={setAnswerInput}
          submitButton={submitButton}
        />
      )}
    </form>
  ) : (
    <RoundButton selected={false} onClick={() => handleButtonSubmit()}>
      <span
        dangerouslySetInnerHTML={{
          __html: answerOption.text
        }}
      />
    </RoundButton>
  );
}
