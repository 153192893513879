import { makeRequestHook } from "../utils/requestHook";
import { get, post } from "../utils/httpUtils";

const endpoint = (patientId: number) => `/v1/patient/${patientId}/sms`;
const endpointMultiple = "/v1/patients/sms";
const endpointTemplates = "/v1/sms/templates";

export type SMS = {
  id: number;
  patientId: number;
  phoneNumber: string;
  message: string;
  link: string | null;
  surveyName?: string;
  twilioId: string;
  twilioStatus: string;
  createdAt: string;
  updatedAt: string;
};

export type SMSTemplate = {
  title: string;
  message: string;
};

export type NewSMS = {
  // id of the patient
  id: number;
  // SMS message content without link
  message: string;
  // Append message text with a personalized link to this survey
  surveyUrl?: string;
  // When dryrun=true sms message won't be sent but all database records are updated even though the message was sent. Useful for debugging and generating data.
  // Default value: false
  dryrun?: boolean;
};

// similar as above, but for multiple patients
export type NewSMSMultiple = {
  patientIds: number[];
  message: string;
  surveyUrl?: string;
  dryrun?: boolean;
};

function getSMS(patientId: number) {
  return (): Promise<SMS[]> => {
    return get<any>(endpoint(patientId));
  };
}

function getSMSTemplates() {
  return (): Promise<SMSTemplate[]> => {
    return get<any>(endpointTemplates);
  };
}

function postSMS(patientId: number) {
  return (data: NewSMS): Promise<SMS> => {
    return post<any>(endpoint(patientId), data, undefined);
  };
}

function postMultipleSMS() {
  return (data: NewSMSMultiple): Promise<SMS> => {
    return post<any>(endpointMultiple, data, undefined);
  };
}

export function useGetSMS(patientId: number) {
  return makeRequestHook<any, {}>(getSMS(patientId))(undefined);
}

export function useGetSMSTemplates() {
  return makeRequestHook<any, {}>(getSMSTemplates())(undefined);
}

export function usePostSMS(patientId: number) {
  return makeRequestHook<any, NewSMS>(postSMS(patientId))(undefined);
}

export function usePostMultipleSMS() {
  return makeRequestHook<any, NewSMSMultiple>(postMultipleSMS())(undefined);
}
