import { useState, useEffect, useCallback } from "react";

type PromiseConstructor<D, P> = (p: P) => Promise<D>;

type RemoteError<E> = E | undefined;

type UseRequestData<D, P, E> = {
  loading: boolean;
  data: D;
  error: RemoteError<E>;
  request: (p: P) => void;
  counter: number;
};

type BaseRequestParam = {
  [name: string]: any;
} | void;

export function makeRequestHook<D, P extends BaseRequestParam = {}, E = {}>(
  promiseConstructor: PromiseConstructor<D, P>
) {
  return (initialValue: D): UseRequestData<D, P, E> => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<D>(initialValue);
    const [error, setError] = useState<RemoteError<E>>(undefined);
    const [requestParams, setRequestParams] = useState<P | {} | undefined>(
      undefined
    );
    const [counter, setCounter] = useState<number>(0);

    useEffect(() => {
      if (!requestParams) {
        return;
      }
      let isMounted = true;

      setLoading(true);

      promiseConstructor(requestParams as P)
        .then(data => {
          if (isMounted) {
            setError(undefined);
            setData(data);
          }
        })
        .catch(err => {
          if (isMounted) {
            setError(err);
            if (console) console.error(err);
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
            setCounter(counter => counter + 1);
          }
        });

      return () => {
        isMounted = false;
      };
    }, [requestParams]);

    const request = useCallback(
      (params: P) => setRequestParams({ ...params }),
      []
    );

    return { loading, data, error, request, counter };
  };
}
