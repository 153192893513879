import React, { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Response } from "../../api/patient";
import AnamnesisTable from "./AnamnesisTable";
import { fmtStdDateStr } from "../../utils/date";

const useStyles = makeStyles(({ palette, typography }: Theme) =>
  createStyles({
    heading: {
      fontSize: typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: typography.pxToRem(15),
      color: palette.text.secondary
    }
  })
);

type AnamnesisPanelProps = {
  patientId: number;
  responses: Response[];
};

export default function AnamnesisPanel({
  patientId,
  responses
}: AnamnesisPanelProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(-1);

  // Expand/collapse
  const handleExpand = (panelIdx: number) =>
    setExpanded((value: number) => (value === panelIdx ? -1 : panelIdx));

  return (
    <React.Fragment>
      {responses.map((resp: Response, idx: number) => (
        <ExpansionPanel
          key={resp.createdAt}
          expanded={expanded === idx}
          onChange={() => handleExpand(idx)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {resp.surveyName}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {fmtStdDateStr(resp.createdAt)}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AnamnesisTable patientId={patientId} qa={resp.allAnswers} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </React.Fragment>
  );
}
