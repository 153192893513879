import { makeRequestHook } from "../utils/requestHook";
import { post } from "../utils/httpUtils";

const endpoint = "/v1/frontend/getQuestion";

export interface Question {
  surveyId: number;
  id: number;
  text: string;
}

export interface AnswerOption {
  id: number;
  text: string;
  target: number;
  props: Record<string, any>;
  ansValue?: string; // text inputs
  type?: string; // not in v1?
  placeholder?: string; // not in v1?
}

export type QuestionData = {
  answerId: number;
  questions: Question[];
  answerOptions: AnswerOption[];
  completedSurveyId?: number;
  createPatient?: boolean;
  surveyFinished?: "finished" | "reset" | "createPatient" | "url";
  url?: string;
};

export function searchToAnswerOption(searchTerm: string): AnswerOption {
  return {
    id: 0,
    text: searchTerm,
    target: 0,
    props: {
      directsearch: true
    }
  };
}

// Generate request body for remote consultation options
export function getConsultationOptionsPayload() {
  return {
    previousAnswerId: 0
  };
}

export function getQueryPayload(query: { q: string | null; v: string | null }) {
  const { q, v } = query;
  return {
    previousAnswerId: 0,
    startSurvey: v ? `${q}|${v}` : `${q}`
  };
}

// Generate request body for direct search case (allKeywords -> search field input)
export function getDirectSearchPayload(searchTerm: string) {
  return {
    previousAnswerId: 0,
    startSurvey: "",
    variables: { 0: { directsearch: searchTerm } }
  };
}

// Generate request body for next API call by looking at previous response data and answer option
export function getNextQuestionPayload(
  prevData: QuestionData,
  answer: AnswerOption
) {
  // For text inputs, we need to store the <input> value
  const textInput =
    (answer && answer.ansValue && { ansValue: answer.ansValue }) || {};

  return {
    previousAnswerId: prevData.answerId,
    thisAnswer: {
      ...textInput,
      surveyId: prevData.questions.slice(-1)[0].surveyId,
      edgeNumber: answer.id,
      targetNode: answer.target,
      nodeNumber: prevData.questions.slice(-1)[0].id
    }
  };
}

function postGetQuestion(data: any): Promise<QuestionData> {
  return post<any>(endpoint, data, undefined);
}

export function useGetQuestion() {
  return makeRequestHook<any, Record<string, any>>(postGetQuestion)(undefined);
}
