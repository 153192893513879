import React, { memo, useEffect, useState, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  IconButton,
  Collapse
} from "@material-ui/core";
import {
  Ticket,
  TicketComment,
  TicketStatus,
  useUpdateStatus,
  useAddCommentToTicket
} from "../../api/ticket";
import TicketCommentForm from "./TicketCommentForm";
import TicketCommentItem from "./TicketCommentItem";
import usePrevious from "../../utils/usePrevious";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    rowWrapper: {
      display: "flex",
      flexDirection: "row"
    },
    colWrapper: {
      display: "flex",
      flexDirection: "column"
    },
    statusContainer: {
      paddingTop: 50,
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
      minWidth: "200px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    mainContainer: {
      flex: 1,
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(2)
    },
    collapseToggle: {
      fontWeight: "bold",
      margin: "-6px 0"
    },
    radioButton: {
      color: theme.palette.text.primary + " !important"
    },
    headerContainer: {
      marginBottom: theme.spacing(1)
    },
    ticketHeader: {
      fontWeight: 600,
      marginRight: 5,
      color: theme.palette.text.primary
    },
    flowText: {
      fontStyle: "italic",
      marginBottom: "1rem",
      color: theme.palette.text.primary
    },
    mainText: {
      lineHeight: "1.6",
      color: theme.palette.text.primary,
      marginBottom: "1rem"
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      "& > :not(:last-child)": {
        marginBottom: theme.spacing(1)
      }
    },
    redStatus: {
      backgroundColor: "#CB3963",
      color: "#FFF"
    },
    greenStatus: {
      backgroundColor: "#3C845D",
      color: "#FFF"
    },
    yellowStatus: {
      backgroundColor: "#ECB449",
      color: theme.palette.text.primary
    },
    blueStatus: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF"
    },
    editInfo: {
      margin: "0 0 0.75rem 1rem",
      color: theme.palette.text.primary,
      fontStyle: "italic",
      fontSize: 14
    },
    comments: {
      borderLeft: `4px solid #eee`
    },
    iconButton: {
      padding: 0,
      marginRight: "0.5rem",
      marginBottom: "0.5rem",
      marginTop: "4px"
    }
  })
);

function TicketListItem(props: TicketListItemProps) {
  const classes = useStyles();
  const item = props.item;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [status, setStatus] = useState<TicketStatus>(item.status);
  const [newSelectedStatus, setNewSelectedStatus] = useState<TicketStatus>(
    item.status
  );
  const updateStatusData = useUpdateStatus();
  const addCommentToTicketData = useAddCommentToTicket();
  const onCommentCreated = useCallback(
    comment => {
      const onCommentCreated = props.onCommentCreated;
      return onCommentCreated(item.id, comment);
    },
    [props.onCommentCreated, item.id]
  );

  useEffect(() => {
    setStatus(item.status);
  }, [item]);

  // Do update only if counter has increased (i.e. there has been a database operation)
  const updateStatusDataPrevCounter = usePrevious(updateStatusData.counter);
  const updateStatusNow =
    updateStatusData.counter !== updateStatusDataPrevCounter &&
    updateStatusData.counter > 0 &&
    !updateStatusData.error;
  useEffect(() => {
    if (updateStatusNow) {
      // Update status locally after db update
      const onStatusChanged = props.onStatusChanged;
      onStatusChanged(item.id, newSelectedStatus);
    }
  }, [updateStatusNow, props.onStatusChanged, item.id, newSelectedStatus]);

  // Do update only if counter has increased (i.e. there has been a database operation)
  const comment = ((addCommentToTicketData || {}).data || {}).comment;
  const addCommentToTicketDataCounter = usePrevious(
    addCommentToTicketData.counter
  );
  const updateCommentNow =
    addCommentToTicketData.counter !== addCommentToTicketDataCounter &&
    addCommentToTicketData.counter > 0 &&
    !addCommentToTicketData.error;
  useEffect(() => {
    if (updateCommentNow && comment) {
      // Update local db to reflect the new comment
      onCommentCreated(comment);
    }
  }, [updateCommentNow, onCommentCreated, comment]);

  const comments = item.comments ? item.comments : [];
  const isLoading = updateStatusData.loading || addCommentToTicketData.loading;

  return (
    <div className={classes.root}>
      <div className={classes.rowWrapper}>
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <span className={classes.ticketHeader}>{item.title}</span>
            {status === "OPEN" &&
              item &&
              item.phoneNumber &&
              `(puh. ${item.phoneNumber})`}
          </div>
          <Collapse in={isExpanded}>
            <div className={classes.flowText}>{item.path}</div>
          </Collapse>
          <div
            className={classes.mainText}
            dangerouslySetInnerHTML={{ __html: escapeTagsExceptBr(item.text) }}
          />
          <div className={classes.buttonsContainer}>
            <Button
              color="primary"
              onClick={() => {
                setIsExpanded(true);
                setShowCommentForm(true);
              }}
            >
              Lisää kommentti
            </Button>
          </div>
          <Collapse in={isExpanded}>
            {showCommentForm && (
              <TicketCommentForm
                ticketId={item.id}
                onCommentCreated={onCommentCreated}
                onCancel={() => setShowCommentForm(false)}
              />
            )}
            <div className={classes.comments}>
              {comments.map((comment, idx) => (
                <TicketCommentItem
                  key={comment.user + comment.createdAt + comment.text + idx}
                  item={comment}
                />
              ))}
            </div>
          </Collapse>
        </div>
        <div className={classes.colWrapper}>
          <div className={classes.actionContainer}>
            <FormControl component="fieldset">
              <RadioGroup
                value={status}
                onChange={event => {
                  const newStatus = event.target.value as TicketStatus;
                  setNewSelectedStatus(newStatus);
                  // Update ticket status in database
                  updateStatusData.request({ id: item.id, status: newStatus });
                  // Add comment to db about status change
                  addCommentToTicketData.request({
                    id: item.id,
                    comment: getChangedStatusComment(item.status, newStatus)
                  });
                }}
              >
                <FormControlLabel
                  value={TicketStatus.Open}
                  className={classes.radioButton}
                  control={
                    <Radio
                      disabled={isLoading}
                      className={classes.radioButton}
                    />
                  }
                  label="Avoin"
                />
                <FormControlLabel
                  value={TicketStatus.InProgress}
                  className={classes.radioButton}
                  control={
                    <Radio
                      disabled={isLoading}
                      className={classes.radioButton}
                    />
                  }
                  label="Oltu yhteydessä"
                />
                <FormControlLabel
                  value={TicketStatus.Closed}
                  className={classes.radioButton}
                  control={
                    <Radio
                      disabled={isLoading}
                      className={classes.radioButton}
                    />
                  }
                  label="Suljettu ottamatta yhteyttä"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.editInfo}>
            {"Pyyntö luotu: " + item.createdAt}
          </div>
        </div>
      </div>
    </div>
  );
}

export interface TicketListItemProps {
  item: Ticket;
  onStatusChanged: (id: string, newStatus: TicketStatus) => void;
  onCommentCreated: (id: string, comment: TicketComment) => void;
}

export default memo(
  TicketListItem,
  (prevProps, nextProps) => prevProps.item === nextProps.item
);

// https://stackoverflow.com/questions/16708158/remove-html-tags-except-br-or-br-tags-with-javascript
function escapeTagsExceptBr(text?: string): string {
  if (!text) {
    return "";
  }

  let html = replaceAll(text, "<br>", "||br||");
  html = replaceAll(html, "<br/>", "||br||");
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  html = tmp.textContent || tmp.innerText;
  return replaceAll(html, "||br||", "<br>");
}

function getChangedStatusComment(fromStatus: string, toStatus: string): string {
  return `Status muuttunut [${fromStatus} → ${toStatus}]`;
}

// https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string
function replaceAll(str: string, search: string, replacement: string): string {
  return str.split(search).join(replacement);
}
