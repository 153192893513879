import { makeRequestHook } from "../utils/requestHook";
import { get } from "../utils/httpUtils";

const endpoint = "/v1/nurse";

export type NurseData = {
  id: string;
  nurse: string;
  provider: string;
  nurseWithProvider: string;
};

const initialValue: NurseData[] = [];

function getNurses(data: any): Promise<NurseData[]> {
  return get<any>(endpoint, undefined);
}

export function useGetNurses() {
  return makeRequestHook<any, {}>(getNurses)(initialValue);
}
