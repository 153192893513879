import { makeRequestHook } from "../utils/requestHook";
import { get } from "../utils/httpUtils";

const endpoint = "/v1/frontend/allKeywords";

export type KeywordsData = string[];

function getAllKeywords(data: any): Promise<KeywordsData> {
  return get<any>(endpoint, undefined);
}

export function useGetAllKeywords() {
  return makeRequestHook<any, {}>(getAllKeywords)(undefined);
}
