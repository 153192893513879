import { proxy } from "../package.json";

/**
 * This is prefix for all API requests to backend.
 * It may be relative or absolute path. In development mdoe relative path
 * requests are proxyed to package.json proxy address.
 */
export const apiPrefix = process.env.REACT_APP_API_PREFIX || "/api";

/**
 * The path for e2e tests. This should be url where React application hosted.
 */
export const test = {
  url: process.env.REACT_APP_TEST_URL || "http://localhost:3000"
};

/**
 * The URL where chat bot is hosted. This setting is used to set the QA/yED
 * test link address (link which opens the chat in a new window)
 */
export const domain =
  process.env.REACT_APP_BOT_URL ||
  (process.env.NODE_ENV === "development" ? proxy : ".");

/**
 * Path where the admin panel is hosted on the server
 */
export const relativePath = process.env.REACT_APP_LOCATION || "/";

/**
 * Use SmokeExit version?
 */
export const isSmokeExit = process.env.REACT_APP_SMOKE_EXIT === "true";
